/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import Button from "../Button";
import "./AddMoreButton.scss";

export default ({ children, ...restProps }) => (
  <Button variant="secondary" className="add-more-button" {...restProps}>
    {children}
  </Button>
);
