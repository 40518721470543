/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useLayoutEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import AuthContext from "../../AuthContext";
import Button from "../Button";
import Logo from "../../assets/images/logo.svg";

import "./Header.scss";

export default ({ navItems }) => {
  const { authActions } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();

  const onLogoClick = () => {
    window.location = "/";
  };

  useLayoutEffect(() => {
    if (authActions.isAuthenticated()) {
      setIsAuthenticated(true);
    }
  }, []);

  const signout = () => {
    authActions.signOut();
  };

  return (
    <div className="header">
      <img src={Logo} alt="Living Trust" onClick={onLogoClick} />
      <div className="nav-items-buttons">
        <div className="nav-items" role="menu">
          {navItems.map((navItem) => (
            <a
              href={navItem.toLink}
              role="menuitem"
              key={navItem.id}
              tabIndex={navItem.id}
              className="nav-item"
            >
              {navItem.name}
            </a>
          ))}
        </div>
        {isAuthenticated ? (
          <>
            <Button variant="secondary" onClick={signout}>
              Sign Out
            </Button>
            {/* <Button variant="secondary" onClick={() => history.push("/form")}>
              Form
            </Button> */}
          </>
        ) : (
          <>
            <Button variant="secondary" onClick={() => history.push("/signin")}>
              Sign In
            </Button>
            <Button onClick={() => history.push("/signup")}>Sign Up</Button>
          </>
        )}
      </div>
    </div>
  );
};
