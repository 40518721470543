/* eslint-disable import/no-cycle */
import UserInfo from "./pages/Form/UserInfo";
import Benificiaries from "./pages/Form/Benificiaries";
import Trustees from "./pages/Form/Trustees";
import Executors from "./pages/Form/Executors";
import GaurdianOfMinor from "./pages/Form/GaurdianOfMinor";
import DurablePowerOfAttorney from "./pages/Form/DurablePowerOfAttorney";
import SpouseDurablePowerOfAttorney from "./pages/Form/SpouseDurablePowerOfAttorney";
import AdvancedHealthCare from "./pages/Form/AdvancedHeathCare";
import SpouseAdvancedHealthCare from "./pages/Form/SpouseAdvancedHealthCare";
import Assets from "./pages/Form/Assets";
import InfoConfirmation from "./pages/Form/InfoConfirmation";

export default [
  {
    id: 1,
    displayText: "Client Information",
    url: "userinformation",
    component: UserInfo,
    key: "userInformation",
  },
  {
    id: 2,
    displayText: "Beneficiaries",
    url: "benificiaries",
    component: Benificiaries,
    key: "benificiaries",
  },
  {
    id: 3,
    displayText: "Successor Trustees",
    url: "trustees",
    component: Trustees,
    key: "trustees",
  },
  {
    id: 4,
    displayText: "Executors",
    url: "executors",
    component: Executors,
    key: "executors",
  },
  {
    id: 5,
    displayText: "Gaurdian of Minor",
    url: "guardianofminor",
    component: GaurdianOfMinor,
    key: "guardianOfMinors",
  },
  {
    id: 6,
    displayText: "Durable PoA",
    url: "durablepowerofattorney",
    component: DurablePowerOfAttorney,
    key: "durablePowerOfAttorney",
  },
  {
    id: 7,
    displayText: "Spouse’s Durable PoA",
    url: "spousedurablepowerofattorney",
    component: SpouseDurablePowerOfAttorney,
    key: "spouseDurablePowerOfAttorney",
  },
  {
    id: 8,
    displayText: "Adv Health",
    url: "advancedhealthcare",
    component: AdvancedHealthCare,
    key: "advancedHealthCare",
  },
  {
    id: 9,
    displayText: "Spouse’s Adv Health",
    url: "spouseadvancedhealthcare",
    component: SpouseAdvancedHealthCare,
    key: "spouseAdvancedHealthCare",
  },
  {
    id: 10,
    displayText: "Assets",
    url: "assets",
    component: Assets,
    key: "assetsDetails",
  },
  {
    id: 11,
    displayText: "Information Confirmation",
    component: InfoConfirmation,
  },
];
// {
//   id: 12,
//   displayText: "Attorney Meeting",
// },
// {
//   id: 13,
//   displayText: "Document Review",
// },
// {
//   id: 14,
//   displayText: "Notarize",
// },
// {
//   id: 15,
//   displayText: "Final Documents",
// },
