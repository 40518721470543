import React from "react";

import "./TextInput.scss";

export default ({
  placeholder,
  type,
  step,
  onChange,
  value,
  error,
  name,
  inputRef,
  onBlur,
  defaultValue,
  disabled,
  readOnly,
}) => (
  <input
    type={type || "text"}
    className={`text-input ${error ? "text-input-error" : ""}`}
    placeholder={placeholder}
    onChange={onChange}
    value={value}
    name={name}
    step={step}
    ref={inputRef}
    onBlur={onBlur}
    defaultValue={defaultValue}
    disabled={disabled}
    readOnly={readOnly}
  />
);
