import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../Button";
import FooterLogo from "../../assets/images/footerLogo.svg";
import instaIcon from "../../assets/images/insta.svg";
import facebookIcon from "../../assets/images/FB.svg";
import LinkedInIcon from "../../assets/images/linkedIn.svg";
import "./Footer.scss";

export default ({ buttons }) => {
  const history = useHistory();
  return (
    <div className="footer">
      <img src={FooterLogo} alt="Living Trust" />
      <div className="footer-links">
        <span>Terms of service</span>
        <span>
          <a href="/#services">Services</a>
        </span>
        <span>
          <a href="/#about">About</a>
        </span>
        <span>
          <a href="/#contact">Contact</a>
        </span>
      </div>
      <div className="footer-social-icons">
        <img src={instaIcon} alt="Instagram" />
        <img src={facebookIcon} alt="Facebook" />
        <img src={LinkedInIcon} alt="LinkedIn" />
      </div>
      <div className="footer-buttons">
        {buttons || (
          <>
            <a href="#about" tabIndex={-1}>
              <Button variant="secondary">More info</Button>
            </a>
            <Button onClick={() => history.push("/signin")}>Get Started</Button>
          </>
        )}
      </div>
    </div>
  );
};
