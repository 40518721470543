/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Modal from "react-bootstrap/Modal";

import AlertYellow from "../../assets/images/alert-yellow.svg";
import "./Alert.scss";

export default ({ show, onYesClick, onNoClick }) => (
  <Modal show={show}>
    <div className="alert-wrapper">
      <div className="alert-wrapper-text">
        <img src={AlertYellow} alt="" />
        <div className="alert-text">
          <span>Are you sure about these changes?</span>
          <span>Changes might impact other sections of the information.</span>
        </div>
      </div>
      <div className="alert-buttons">
        <div onClick={onYesClick} className="alert-yes">
          Yes
        </div>
        <div onClick={onNoClick} className="alert-no">
          No
        </div>
      </div>
    </div>
  </Modal>
);
