const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_CALLBACK_URL,
  REACT_APP_AUTH0_REALM,
  REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_AUTH0_SCOPE,
} = process.env;

export default {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENTID,
  callbackUrl: REACT_APP_AUTH0_CALLBACK_URL,
  realm: REACT_APP_AUTH0_REALM,
  apiAudience: REACT_APP_AUTH0_API_AUDIENCE,
  scope: REACT_APP_AUTH0_SCOPE,
};
