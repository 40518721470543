import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const URL = `${REACT_APP_API_URL}`;

const NON_SECURE_API = axios.create({
  baseURL: URL,
});

export default NON_SECURE_API;
