import React from "react";
import { useSelector } from "react-redux";
import Video from "../../../../components/Video";
import nextStepsVideo from "../../../../assets/videos/nextStepsVideo.mp4";

import BackButton from "../../../../components/BackButton";
import NextButton from "../../../../components/NextButton";
import FormCard from "../../../../components/FormCard";
import { getOnlyFirstNameFromName } from "../../../../helpers";
// import "../../../Home/VideoSection/VideoSections.scss";

export default ({ goBackSubItem, goToNextNavItem }) => {
  const profile = useSelector((state) => state.profile);
  const onNextButtonClick = () => {
    goToNextNavItem();
  };
  return (
    <div className="address-details-wrapper">
      <FormCard>
        <span className="welcome-user">
          {getOnlyFirstNameFromName(profile.name)}
        </span>
        <span className="welcome-user-desc">
          Just a quick hello and some information from your attorney.
        </span>
      </FormCard>
      <FormCard>
        <Video mp4source={nextStepsVideo} />
      </FormCard>{" "}
      <div className="form-details-buttons">
        <BackButton type="button" onClick={goBackSubItem} />
        <NextButton type="button" onClick={onNextButtonClick} />
      </div>
    </div>
  );
};
