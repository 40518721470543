/* eslint-disable react/button-has-type */
import React from "react";

import DotLoader from "../DotLoader";
import "./Button.scss";

export default ({
  children,
  variant,
  onClick,
  className = "",
  type = "button",
  isLoading,
  disabled,
}) => (
  <button
    type={type}
    className={`button ${variant === "secondary" ? "button-secondary" : ""} ${
      className ? `${className}` : ""
    } ${disabled ? "button-disabled" : ""}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children} {isLoading && <DotLoader />}
  </button>
);
