import React, { useRef } from "react";

import AddMoreButton from "../AddMoreButton";
// import DotLoader from "../DotLoader";
import "./FileUpload.scss";

export default ({ handleInputFilesChanged, isLoading }) => {
  const inputFileRef = useRef();

  return (
    <div className="file-upload-wrapper">
      <input
        type="file"
        name="file"
        ref={inputFileRef}
        onChange={handleInputFilesChanged}
        style={{ display: "none" }}
      />
      <AddMoreButton
        onClick={() => {
          if (!isLoading) {
            inputFileRef.current.value = null;
            inputFileRef.current.click();
          }
        }}
      >
        {isLoading ? "Uploading..." : "Upload Grant Deed"}
      </AddMoreButton>
    </div>
  );
};
