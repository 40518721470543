import { call, put, takeLatest } from "redux-saga/effects";

import { formActions } from "../actions/form.actions";
import API from "../services/api";

function* fetchFormData() {
  try {
    const response = yield call(API.get, "/form/userinfo");
    yield put({
      type: formActions.FETCH_FORM_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.FETCH_FORM_DATA_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchFetchFormData() {
  yield takeLatest(formActions.FETCH_FORM_DATA, fetchFormData);
}

function* postFormData({ payload }) {
  try {
    const response = yield call(API.post, "/form/userinfo", payload);
    yield put({
      type: formActions.POST_FORM_DATA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.POST_FORM_DATA_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchPostFormData() {
  yield takeLatest(formActions.POST_FORM_DATA, postFormData);
}

function* postSavedNames({ payload }) {
  try {
    const response = yield call(API.post, "/form/savednames", payload);
    yield put({
      type: formActions.POST_SAVED_NAMES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.POST_SAVED_NAMES_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchPostSavedNames() {
  yield takeLatest(formActions.POST_SAVED_NAMES, postSavedNames);
}

function* getSavedNames() {
  try {
    const response = yield call(API.get, "/form/savednames");
    yield put({
      type: formActions.GET_SAVED_NAMES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.GET_SAVED_NAMES_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchGetSavedNames() {
  yield takeLatest(formActions.GET_SAVED_NAMES, getSavedNames);
}

function* getNavItems() {
  try {
    const response = yield call(API.get, "/form/navitems");
    yield put({
      type: formActions.GET_NAV_ITEMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.GET_NAV_ITEMS_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchGetNavItems() {
  yield takeLatest(formActions.GET_NAV_ITEMS, getNavItems);
}

function* postNavItems({ payload }) {
  try {
    yield call(API.post, "/form/navitems", payload);
  } catch (error) {
    // yield put({
    //   type: formActions.POST_FORM_DATA_ERROR,
    //   payload:
    //     error.response.data.errorMessage ||
    //     "Something went wrong. Please try again",
    // });
    console.log("error in posting navItems", error);
  }
}

function* watchPostNavItems() {
  yield takeLatest(formActions.POST_NAV_ITEMS, postNavItems);
}

function* getQuestions() {
  try {
    const response = yield call(API.get, "/form/questions");
    yield put({
      type: formActions.GET_QUESTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.GET_QUESTIONS_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchGetQuestions() {
  yield takeLatest(formActions.GET_QUESTIONS, getQuestions);
}

function* postQuestions({ payload }) {
  try {
    const response = yield call(API.post, "/form/questions", payload);
    yield put({
      type: formActions.POST_QUESTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: formActions.POST_QUESTIONS_ERROR,
      payload:
        error.response.data.errorMessage ||
        "Something went wrong. Please try again",
    });
  }
}

function* watchPostQuestions() {
  yield takeLatest(formActions.POST_QUESTIONS, postQuestions);
}

function* submitUserInfo() {
  try {
    yield call(API.post, "/form/submituserinfo");
  } catch (error) {
    console.log("Error in submitting user info", error);
  }
}

function* watchSubmitUserInfo() {
  yield takeLatest(formActions.SUBMIT_USER_INFO, submitUserInfo);
}

export default [
  watchFetchFormData(),
  watchPostFormData(),
  watchGetNavItems(),
  watchPostNavItems(),
  watchPostSavedNames(),
  watchGetSavedNames(),
  watchGetQuestions(),
  watchPostQuestions(),
  watchSubmitUserInfo(),
];
