export const profileActions = {
  UPDATE_PROFILE: "UPDATE_PROFILE",
};

export const profileActionCreators = {
  updateProfile: (payload) => ({
    type: profileActions.UPDATE_PROFILE,
    payload,
  }),
};
