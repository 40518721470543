/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

import "./CheckBox.scss";

export default function CheckBox({
  large,
  checked,
  disabled,
  intermediate,
  onChange,
}) {
  return (
    <div className={`cb-wrapper ${large ? "cb-large" : ""}`} onClick={onChange}>
      <input
        type="checkbox"
        className={`checkbox ${intermediate ? "cb-intermediate" : ""}`}
        checked={checked}
        disabled={disabled}
        readOnly
      />
      <span />
    </div>
  );
}
