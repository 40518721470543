export const formActions = {
  FETCH_FORM_DATA: "FETCH_FORM_DATA",
  FETCH_FORM_DATA_SUCCESS: "FETCH_FORM_DATA_SUCCESS",
  FETCH_FORM_DATA_ERROR: "FETCH_FORM_DATA_ERROR",
  POST_FORM_DATA: "POST_FORM_DATA",
  POST_FORM_DATA_SUCCESS: "POST_FORM_DATA_SUCCESS",
  POST_FORM_DATA_ERROR: "POST_FORM_DATA_ERROR",
  POST_NAV_ITEMS: "POST_NAV_ITEMS",
  GET_NAV_ITEMS: "GET_NAV_ITEMS",
  GET_NAV_ITEMS_SUCCESS: "GET_NAV_ITEMS_SUCCESS",
  GET_NAV_ITEMS_ERROR: "GET_NAV_ITEMS_ERROR",
  POST_SAVED_NAMES: "POST_SAVED_NAMES",
  POST_SAVED_NAMES_SUCCESS: "POST_SAVED_NAMES_SUCCESS",
  POST_SAVED_NAMES_ERROR: "POST_SAVED_NAMES_ERROR",
  GET_SAVED_NAMES: "GET_SAVED_NAMES",
  GET_SAVED_NAMES_SUCCESS: "GET_SAVED_NAMES_SUCCESS",
  GET_SAVED_NAMES_ERROR: "GET_SAVED_NAMES_ERROR",
  GET_QUESTIONS: "GET_QUESTIONS",
  GET_QUESTIONS_SUCCESS: "GET_QUESTIONS_SUCCESS",
  GET_QUESTIONS_ERROR: "GET_QUESTIONS_ERROR",
  POST_QUESTIONS: "POST_QUESTIONS",
  POST_QUESTIONS_SUCCESS: "POST_QUESTIONS_SUCCESS",
  POST_QUESTIONS_ERROR: "POST_QUESTIONS_ERROR",
  UPDATE_NAVIGATION_STATE: "UPDATE_NAVIGATION_STATE",
  UPDATE_NAVIGATION_STAT_WITH_NAME: "UPDATE_NAVIGATION_STAT_WITH_NAME",
  SUBMIT_USER_INFO: "SUBMIT_USER_INFO",
};

export const formActionCreators = {
  fetchFormData: () => ({
    type: formActions.FETCH_FORM_DATA,
  }),
  postFormData: (payload) => ({
    type: formActions.POST_FORM_DATA,
    payload,
  }),
  postNavItems: (payload) => ({
    type: formActions.POST_NAV_ITEMS,
    payload,
  }),
  getNavItems: () => ({
    type: formActions.GET_NAV_ITEMS,
  }),
  postSavedNames: (payload) => ({
    type: formActions.POST_SAVED_NAMES,
    payload,
  }),
  getSavedNames: () => ({
    type: formActions.GET_SAVED_NAMES,
  }),
  getQuestions: () => ({
    type: formActions.GET_QUESTIONS,
  }),
  postQuestions: (payload) => ({
    type: formActions.POST_QUESTIONS,
    payload,
  }),
  updateNavigationState: (payload) => ({
    type: formActions.UPDATE_NAVIGATION_STATE,
    payload,
  }),
  updateNavigationStateWithName: (payload) => ({
    type: formActions.UPDATE_NAVIGATION_STAT_WITH_NAME,
    payload,
  }),
  submitUserInfo: () => ({
    type: formActions.SUBMIT_USER_INFO,
  }),
};
