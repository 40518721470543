import React from "react";
import Radio from "../Radio";

import "./FormRadio.scss";

export default ({ inputRef, name, options, label, onBlur, onChange }) => (
  <div className="form-radio">
    <span>{label}</span>
    <span>
      <Radio
        name={name}
        options={options}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
      />
    </span>
  </div>
);
