import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NewSavedName from "../../../components/NewSavedName";
import FormInput from "../../../components/FormInput";
import FormCard from "../../../components/FormCard";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import { formActionCreators } from "../../../actions/form.actions";
import { getFullChildren, getMinorChildren } from "../../../helpers";

const gaurdianOfMinorSchema = yup.object().shape({
  primaryGaurdianOfMinor: yup.string(),
  secondaryGaurdianOfMinor: yup.string(),
  localGaurdianOfMinor: yup.string(),
});

export default ({ goToNextNavItem }) => {
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { guardianOfMinors } = userInfo || {};
  const {
    primaryGaurdianOfMinor,
    secondaryGaurdianOfMinor,
    localGaurdianOfMinor,
    ...restGuardianOfMinors
  } = guardianOfMinors || {};

  const { userInformation } = userInfo;
  const fullChildren = getFullChildren(userInformation);
  const minorChildren = getMinorChildren(fullChildren);
  console.log("minorChildren", minorChildren);

  let minorNamesConcatinated = "";

  const getConcatIdentifier = (minorIndex) => {
    if (minorIndex === 0) {
      return "";
    }
    if (minorChildren.length === minorIndex + 1) {
      return " and";
    }
    return ",";
  };

  (minorChildren || []).forEach((minor, index) => {
    minorNamesConcatinated += `${getConcatIdentifier(index)} ${
      minor.firstName
    }`;
  });

  const { register, setValue, errors, handleSubmit, getValues } = useForm({
    resolver: yupResolver(gaurdianOfMinorSchema),
    defaultValues: {
      ...restGuardianOfMinors,
      primaryGaurdianOfMinor: primaryGaurdianOfMinor || "",
      secondaryGaurdianOfMinor: secondaryGaurdianOfMinor || "",
      localGaurdianOfMinor: localGaurdianOfMinor || "",
    },
  });

  const onSavedNameSubmit = ({ name, data }) => {
    if (name === "primaryGaurdianOfMinor") {
      setValue("primaryGaurdianOfMinor", `${data.firstName} ${data.lastName}`);
    }
    if (name === "secondaryGaurdianOfMinor") {
      setValue(
        "secondaryGaurdianOfMinor",
        `${data.firstName} ${data.lastName}`
      );
    }
    if (name === "localGaurdianOfMinor") {
      setValue("localGaurdianOfMinor", `${data.firstName} ${data.lastName}`);
    }
  };

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`${itemIndex}`, `${savedName.firstName} ${savedName.lastName}`);
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  const dispatch = useDispatch();
  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onGuardiansSubmit = (data) => {
    dispatch(
      formActionCreators.postFormData({
        guardianOfMinors: data,
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        guardianOfMinors: data,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  return (
    <div className="guardian-of-minor-wrapper">
      <FormCard>
        <span className="form-header-main-title">
          {`Guardian of ${minorNamesConcatinated}`}
        </span>
        <span className="form-title-desc">
          The Guardian is someone that you trust to take care of your children
          if disaster strikes. It can be the same person as the Successor
          Trustee or someone completely different. Sometimes the person who is
          good with money and paperwork is not the best at doing kindergarten
          art projects. It is good to have a primary and a backup guardian. It
          is also wise to pick someone local if you choose your family member on
          the east coast to be the Primary Guardian.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onGuardiansSubmit)}>
        <FormCard>
          <span className="form-header-title">Primary guardian of minor</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "primaryGaurdianOfMinor"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "primaryGaurdianOfMinor")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "primaryGaurdianOfMinor")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Primary guardian of minor"
                name="primaryGaurdianOfMinor"
                error={errors.primaryGaurdianOfMinor?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("primaryGaurdianOfMinor")}
                onKeyPress={() => setOpenNewSavedName("primaryGaurdianOfMinor")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "primaryGaurdianOfMinor" && (
          <NewSavedName
            title="New Guardian"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">Secondary guardian of minor</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "secondaryGaurdianOfMinor"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "secondaryGaurdianOfMinor")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "secondaryGaurdianOfMinor")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Secondary guardian of minor"
                name="secondaryGaurdianOfMinor"
                error={errors.secondaryGaurdianOfMinor?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("secondaryGaurdianOfMinor")}
                onKeyPress={() =>
                  setOpenNewSavedName("secondaryGaurdianOfMinor")
                }
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "secondaryGaurdianOfMinor" && (
          <NewSavedName
            title="New Guardian"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">
            Local guardian of minor(If Primary Gaurdian is not local)
          </span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "localGaurdianOfMinor"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "localGaurdianOfMinor")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "localGaurdianOfMinor")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Local guardian of minor"
                name="localGaurdianOfMinor"
                error={errors.localGaurdianOfMinor?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("localGaurdianOfMinor")}
                onKeyPress={() => setOpenNewSavedName("localGaurdianOfMinor")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "localGaurdianOfMinor" && (
          <NewSavedName
            title="New Guardian"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
