/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Alert from "../../../../components/Alert";
import BackButton from "../../../../components/BackButton";
import NextButton from "../../../../components/NextButton";
import FormRadio from "../../../../components/FormRadio";
import FormInput from "../../../../components/FormInput";
import FormCard from "../../../../components/FormCard";
import UserInfoContinueCard from "../../../../components/UserInfoContinueCard";
import { formActionCreators } from "../../../../actions/form.actions";
import { checkIfNamePresentInSavedNames } from "../../../../helpers";
import "./ChildrenDetails.scss";

const childrenSchema = {
  firstName: yup.string(),
  lastName: yup.string(),
  dob: yup.string(),
};

const childrenDetailsSchema = yup.object().shape({
  children: yup.array().of(yup.object().shape(childrenSchema)),
  hasChildrenFromPriorMarriage: yup.string().required("This field is required"),
  priorMarriageChildren: yup
    .string()
    .when("hasChildrenFromPriorMarriage", (hasChildrenFromPriorMarriage) => {
      if (hasChildrenFromPriorMarriage === "yes")
        return yup
          .number()
          .typeError("Children From Prior Marriage must be a number")
          .required("* Children From Prior Marriage is required");
      return yup.string();
    }),
  hasDeceasedChild: yup.string().required("This field is required"),
  deceasedChild: yup.string().when("hasDeceasedChild", (hasDeceasedChild) => {
    if (hasDeceasedChild === "yes")
      return yup
        .number()
        .typeError("This field must be a number")
        .required("* This Field is required");
    return yup.string();
  }),
});

export default ({
  setActiveUserInfomSubItems,
  activeUserInformSubItems,
  goBackSubItem,
}) => {
  const [dragOver, setDragover] = useState(null);
  const dispatch = useDispatch();
  const userInfoState = useSelector((state) => state.userInfoState);
  const profile = useSelector((state) => state.profile);
  const { userInfo } = userInfoState;
  const { userInformation, benificiaries } = userInfo || {};
  const {
    childrenDetails,
    addressDetails,
    priorMarriageChildrenDetails,
    deceasedChildrenDetails,
  } = userInformation || {};
  const {
    children,
    hasChildrenFromPriorMarriage,
    hasDeceasedChild,
    ...restChildrenDetails
  } = childrenDetails || {};
  const { noOfChildren } = addressDetails || {};

  const defaultChildren = Array.from({ length: noOfChildren }).fill({
    firstName: "",
    lastName: "",
    dob: "",
  });

  const {
    register,
    control,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(childrenDetailsSchema),
    mode: "onChange",
    defaultValues: {
      ...restChildrenDetails,
      children: (children || []).length > 0 ? children : defaultChildren,
      hasChildrenFromPriorMarriage: hasChildrenFromPriorMarriage || "no",
      hasDeceasedChild: hasDeceasedChild || "no",
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "children",
  });

  const watchHasChildrenFromPriorMarriage = watch(
    "hasChildrenFromPriorMarriage"
  );
  const watchHasDeceasedChild = watch("hasDeceasedChild");
  const watchChildren = watch("children");

  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = () => {
    const newNames = [];
    (watchChildren || []).forEach((child) => {
      const childFound = checkIfNamePresentInSavedNames(savedNames, {
        firstName: child.firstName,
        lastName: child.lastName,
      });
      if (!childFound && (child.firstName !== "" || child.lastName !== "")) {
        newNames.push({ ...child });
      }
    });
    if ((newNames || []).length > 0) {
      dispatch(formActionCreators.postSavedNames([...savedNames, ...newNames]));
    }
  };

  const addOrModifyBenificiaries = (newChildren) => {
    const newChildrenlength = (newChildren || []).length;
    const eachChildSplit = 100 / newChildrenlength;
    const newChildBenificiaries = (newChildren || []).map((child) => ({
      name: `${child?.firstName || ""} ${child.lastName || ""}`,
      splitPercent: eachChildSplit,
    }));
    dispatch(
      formActionCreators.postFormData({
        benificiaries: {
          ...benificiaries,
          childrenCount: newChildren.length,
          childBenificiariesSplit: newChildBenificiaries,
        },
      })
    );
  };

  const submitChildrenDetails = (data) => {
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          childrenDetails: data,
        },
      })
    );
    const { childBenificiariesSplit, childrenCount } = benificiaries;
    const newChildren = data.children;
    if (childrenCount !== (newChildren || []).length) {
      addOrModifyBenificiaries(newChildren);
    }
    saveNamesToSavedNames();
    if (watchHasChildrenFromPriorMarriage === "yes") {
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "priorMarriageChildrenDetails",
      ]);
    } else if (watchHasDeceasedChild === "yes") {
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "deceasedChildrenDetails",
      ]);
    } else {
      setActiveUserInfomSubItems([...activeUserInformSubItems, "videoDetails"]);
    }
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          childrenDetails: data,
        },
      })
    );
  };

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`children[${itemIndex}].firstName`, savedName.firstName || "");
    setValue(`children[${itemIndex}].lastName`, savedName.lastName || "");
    setValue(`children[${itemIndex}].dob`, savedName.dob || "");
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  // const [showDeceasedChildrenAlert, setShowDeceasedChildrenAlert] = useState(
  //   false
  // );
  // const validateAndSaveDecesedChild = (e) => {
  //   const { value } = e.target;
  //   const prevdeceasedChild = restChildrenDetails.deceasedChild;
  //   if (value !== prevdeceasedChild && value && prevdeceasedChild) {
  //     setShowDeceasedChildrenAlert(value);
  //   }
  // };
  // const onDeceasedChildYesClick = () => {
  //   const newDeceasedChild = showDeceasedChildrenAlert;
  //   const newChildren = Array.from({
  //     length: newDeceasedChild,
  //   }).fill({
  //     firstName: "",
  //     lastName: "",
  //     dob: "",
  //   });
  //   dispatch(
  //     formActionCreators.postFormData({
  //       userInformation: {
  //         deceasedChildrenDetails: {
  //           ...deceasedChildrenDetails,
  //           children: newChildren,
  //         },
  //       },
  //     })
  //   );
  //   setShowDeceasedChildrenAlert(null);
  // };
  // const onDeceasedChildNoClick = () => {
  //   setValue("deceasedChild", restChildrenDetails.deceasedChild);
  //   setShowDeceasedChildrenAlert(null);
  // };

  const validateAndUpdatePriorMarriageChildren = (e) => {
    const { value } = e.target;
    if (value === "no") {
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            priorMarriageChildrenDetails: {},
          },
        })
      );
    }
    triggerAutoSave();
  };

  const validateAndUpdateDeceasedChild = (e) => {
    const { value } = e.target;
    if (value === "no") {
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            deceasedChildrenDetails: {},
          },
        })
      );
    }
    triggerAutoSave();
  };

  const [
    showPriorMarriageChildrenAlert,
    setShowPriorMarriageChildrenAlert,
  ] = useState(false);
  const currentPriorMarriageChildren =
    restChildrenDetails.priorMarriageChildren;

  const updatePriorMarriageChildrenInNextPages = (newPriorMarriageChildren) => {
    if (newPriorMarriageChildren) {
      const currentPriorMarriageChildrenDetails = priorMarriageChildrenDetails;
      let newPriorMarriageChildrenDetails;
      if (
        currentPriorMarriageChildrenDetails.children.length >
        newPriorMarriageChildren
      ) {
        newPriorMarriageChildrenDetails = {
          ...currentPriorMarriageChildrenDetails,
          children: (currentPriorMarriageChildrenDetails.children || []).slice(
            0,
            newPriorMarriageChildren
          ),
        };
      } else {
        newPriorMarriageChildrenDetails = {
          ...currentPriorMarriageChildrenDetails,
          children: [
            ...currentPriorMarriageChildrenDetails.children,
            ...Array.from({
              length:
                newPriorMarriageChildren -
                currentPriorMarriageChildrenDetails.children.length,
            }).fill({
              firstName: "",
              lastName: "",
              dob: "",
            }),
          ],
        };
      }
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            priorMarriageChildrenDetails: newPriorMarriageChildrenDetails,
          },
        })
      );
    }
  };

  const verifyAndUpdatePriorMarriaheChildren = (e) => {
    const newPriorMarriageChildren = e.target.value;
    if (
      Number(currentPriorMarriageChildren) !==
        Number(newPriorMarriageChildren) &&
      currentPriorMarriageChildren &&
      newPriorMarriageChildren
    ) {
      setShowPriorMarriageChildrenAlert(newPriorMarriageChildren);
    } else {
      updatePriorMarriageChildrenInNextPages(newPriorMarriageChildren);
    }
  };
  const onPrevMarriageChildrenYesClick = () => {
    updatePriorMarriageChildrenInNextPages(showPriorMarriageChildrenAlert);
    setShowPriorMarriageChildrenAlert(null);
  };
  const onPrevMarriageChildrenNoClick = () => {
    setValue("priorMarriageChildren", currentPriorMarriageChildren);
    setShowPriorMarriageChildrenAlert(null);
  };

  const currentDeceasedChildren = restChildrenDetails.deceasedChild;
  const [showDeceasedChildrenAlert, setShowDeceasedChildrenAlert] = useState(
    false
  );

  const updateDeceasedChildrenDetailsInNextPage = (newDeceasedChildren) => {
    if (newDeceasedChildren) {
      const currentDeceasedChildrenDetails = deceasedChildrenDetails;
      let newDeceasedChildrenDetails;
      if (
        currentDeceasedChildrenDetails.children.length > newDeceasedChildren
      ) {
        newDeceasedChildrenDetails = {
          ...currentDeceasedChildrenDetails,
          children: (currentDeceasedChildrenDetails.children || []).slice(
            0,
            newDeceasedChildren
          ),
        };
      } else {
        newDeceasedChildrenDetails = {
          ...currentDeceasedChildrenDetails,
          children: [
            ...currentDeceasedChildrenDetails.children,
            ...Array.from({
              length:
                newDeceasedChildren -
                currentDeceasedChildrenDetails.children.length,
            }).fill({
              firstName: "",
              lastName: "",
              dob: "",
            }),
          ],
        };
      }
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            deceasedChildrenDetails: newDeceasedChildrenDetails,
          },
        })
      );
    }
  };

  const verifyAndUPdateDeceasedChildren = (e) => {
    const newDeceasedChildren = e.target.value;
    if (
      Number(currentDeceasedChildren) !== Number(newDeceasedChildren) &&
      currentDeceasedChildren &&
      newDeceasedChildren
    ) {
      setShowDeceasedChildrenAlert(newDeceasedChildren);
    } else {
      updateDeceasedChildrenDetailsInNextPage(newDeceasedChildren);
    }
  };

  const onDeceasedChildYesClick = () => {
    updateDeceasedChildrenDetailsInNextPage(showDeceasedChildrenAlert);
    setShowDeceasedChildrenAlert(null);
  };

  const onDeceasedChildNoClick = () => {
    setValue("deceasedChild", currentDeceasedChildren);
    setShowDeceasedChildrenAlert(null);
  };

  return (
    <div>
      <UserInfoContinueCard name="Please tell us about your Child[ren]" />
      <form onSubmit={handleSubmit(submitChildrenDetails)}>
        <FormCard>
          <span className="form-title">Children</span>
          {fields.map((item, index) => (
            <div
              className={`form-controls-children ${
                dragOver === index ? "form-controls-children-drag-over" : ""
              }`}
              key={item.id}
              onDrop={(e) => onDropSavedNames(e, index)}
              onDragOver={(e) => onDragOverSavedNames(e, index)}
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                label="First Name"
                name={`children[${index}].firstName`}
                error={errors?.children?.[index]?.firstName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.firstName}
              />
              <FormInput
                label="Last Name"
                name={`children[${index}].lastName`}
                error={errors?.children?.[index]?.lastName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.lastName}
              />
              <FormInput
                label="DOB"
                name={`children[${index}].dob`}
                type="date"
                error={errors?.children?.[index]?.dob?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.dob}
              />
            </div>
          ))}

          <div className="form-controls">
            <FormRadio
              label="Children from prior marriage/ Partner?"
              name="hasChildrenFromPriorMarriage"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onChange={validateAndUpdatePriorMarriageChildren}
            />
            {watchHasChildrenFromPriorMarriage === "yes" && (
              <div className="form-controls-text-input">
                <span>Number of Prior Marriage Children?</span>
                <span>
                  <FormInput
                    name="priorMarriageChildren"
                    error={errors.priorMarriageChildren?.message}
                    inputRef={register}
                    onBlur={verifyAndUpdatePriorMarriaheChildren}
                  />
                </span>
              </div>
            )}
            <FormRadio
              label="Deceased child ?"
              name="hasDeceasedChild"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onChange={validateAndUpdateDeceasedChild}
            />
            {watchHasDeceasedChild === "yes" && (
              <div className="form-controls-text-input">
                <span>Number of Deceased Children</span>
                <span>
                  <FormInput
                    name="deceasedChild"
                    error={errors.deceasedChild?.message}
                    inputRef={register}
                    onBlur={verifyAndUPdateDeceasedChildren}
                  />
                </span>
              </div>
            )}
          </div>
        </FormCard>
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBackSubItem} />
          <NextButton type="submit" disabled={userInfoState.isPositingData} />
        </div>
      </form>
      <Alert
        show={showPriorMarriageChildrenAlert}
        onYesClick={onPrevMarriageChildrenYesClick}
        onNoClick={onPrevMarriageChildrenNoClick}
      />
      <Alert
        show={showDeceasedChildrenAlert}
        onYesClick={onDeceasedChildYesClick}
        onNoClick={onDeceasedChildNoClick}
      />
    </div>
  );
};
