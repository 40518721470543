import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import AuthWrapper from "../../components/AuthWrapper";
import Button from "../../components/Button";
import FormError from "../../components/FormError";
import NON_SECURE_API from "../../services/non-secure-api";
import "./SignupConfirm.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const [signupConfirmState, setSignupConfirmState] = useState({
    isLoading: false,
    errorMessage: "",
    successMessage: "",
  });

  const query = useQuery();

  const email = query.get("email");

  const resendEmail = () => {
    setSignupConfirmState({
      ...signupConfirmState,
      isLoading: true,
      errorMessage: "",
    });
    NON_SECURE_API.post("/auth/resend-email", { email })
      .then(() => {
        setSignupConfirmState({
          ...signupConfirmState,
          isLoading: false,
          errorMessage: "",
          successMessage: "Email has been sent again",
        });
      })
      .catch((err) => {
        setSignupConfirmState({
          ...signupConfirmState,
          isLoading: false,
          errorMessage: err.response.data.message,
        });
      });
  };

  return (
    <AuthWrapper page="signupconfirm">
      <span className="signupconfirm-text">
        Living Trust from your Living Room, saving you time and money.
      </span>
      {signupConfirmState.successMessage && (
        <div className="signupconfirm-success">
          {signupConfirmState.successMessage}
        </div>
      )}
      <form className="signupconfirm-form">
        <div className="signupconfirm-form-error">
          {signupConfirmState.errorMessage && (
            <FormError error={signupConfirmState.errorMessage} />
          )}
        </div>
        <span>Please verify your email</span>
        <span>You’re almost there! We sent an email to</span>
        <span>{email}</span>
        <span>
          Just click on the link in the email to complete your signup. If you
          dont see it, you may need to check your spam folder.
        </span>
        <span>Still can’t find the email?</span>
        <Button
          className="signupconfirm-resendemail"
          onClick={resendEmail}
          isLoading={signupConfirmState.isLoading}
        >
          Resend Email
        </Button>
        <div className="signupconfirm-contactus">
          Need help?{" "}
          <span>
            <a href="/#contact">Contact us</a>
          </span>
        </div>
      </form>
    </AuthWrapper>
  );
};
