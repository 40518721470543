/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import Button from "../Button";
import "./BackButton.scss";

export default (props) => (
  <Button {...props} className="back-button" variant="secondary">
    Back
  </Button>
);
