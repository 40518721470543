import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormInput from "../FormInput";
import FormCard from "../FormCard";
import Button from "../Button";
import {
  checkIfNamePresentInSavedNames,
  formatPhoneNumber,
} from "../../helpers";
import { formActionCreators } from "../../actions/form.actions";

import "./NewSavedName.scss";

const newSavedNameSchema = yup.object().shape({
  firstName: yup.string().required("* First Name  is required"),
  lastName: yup.string().required("* LastName is required"),
  address: yup.string(),
  city: yup.string(),
  county: yup.string(),
  state: yup.string(),
  zipCode: yup
    .number()
    .nullable(true)
    .transform((_, val) => val || null),
  email: yup.string().email("Email is not valid"),
  phone: yup.string(),
});

export default ({
  onClose,
  title,
  onSavedNameSubmit,
  openNewSavedName,
  type,
}) => {
  const { register, errors, trigger, getValues, setValue } = useForm({
    resolver: yupResolver(newSavedNameSchema),
  });

  const dispatch = useDispatch();
  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = () => {
    const data = getValues();
    const elementFound = checkIfNamePresentInSavedNames(savedNames, {
      firstName: data.firstName,
      lastName: data.lastName,
    });

    if (!elementFound) {
      dispatch(
        formActionCreators.postSavedNames([
          ...savedNames,
          {
            ...data,
          },
        ])
      );
    }
  };

  const saveSavedName = (e) => {
    e.stopPropagation();
    trigger();
    if (getValues().firstName !== "" || getValues().lastName !== "") {
      saveNamesToSavedNames();
      onSavedNameSubmit({ name: openNewSavedName, data: getValues() });
    }
    onClose();
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setValue("phone", formattedPhone);
  };

  return (
    <FormCard showCloseIcon onFormCardCloseClick={onClose}>
      <form>
        <span className="form-title">{title}</span>
        <div className="form-controls">
          <div className="form-controls-section">
            <FormInput
              label="First Name"
              name="firstName"
              error={errors.firstName?.message}
              inputRef={register}
            />
            <FormInput
              label={`Last Name${
                title === "New Benificiary" ? "/Company" : ""
              }`}
              name="lastName"
              error={errors.lastName?.message}
              inputRef={register}
            />
          </div>
          <FormInput
            label="Address"
            name="address"
            error={errors.address?.message}
            inputRef={register}
          />
          <div className="form-controls-section">
            <FormInput
              label="City"
              name="city"
              error={errors.city?.message}
              inputRef={register}
            />
            <FormInput
              label="County"
              name="county"
              error={errors.county?.message}
              inputRef={register}
            />
          </div>
          <div className="form-controls-section">
            <FormInput
              label="State"
              name="state"
              error={errors.state?.message}
              inputRef={register}
            />
            <FormInput
              label="Zip Code"
              name="zipCode"
              error={errors.zipCode?.message}
              inputRef={register}
            />
          </div>
          <div className="form-controls-section">
            <FormInput
              label="Email"
              name="email"
              error={errors.email?.message}
              inputRef={register}
            />
            <FormInput
              label="Phone"
              name="phone"
              error={errors.phone?.message}
              inputRef={register}
              onChange={handlePhoneChange}
            />
          </div>
          {type === "benificiary" && (
            <div className="form-controls-text-input">
              <span>Benefits percentage split</span>
              <span>
                <div className="split-percent-textbox-new-benificaiary">
                  <FormInput
                    label="Split"
                    name="splitPercent"
                    error={errors.splitPercent?.message || ""}
                    inputRef={register()}
                  />
                  <div className="split-percent-textbox-text">%</div>
                </div>
              </span>
            </div>
          )}
        </div>
        <div className="form-details-buttons-savednames">
          <Button onClick={saveSavedName}>Save</Button>
        </div>
      </form>
    </FormCard>
  );
};
