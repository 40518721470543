import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NewSavedName from "../../../components/NewSavedName";
import FormCard from "../../../components/FormCard";
import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import { formActionCreators } from "../../../actions/form.actions";
import { getOnlyFirstNameFromName } from "../../../helpers";

const advancedHealthCareSchema = yup.object().shape({
  firstAdvanceHealthCare: yup.string(),
  secondAdvanceHealthCare: yup.string(),
  extraNotes: yup.string(),
});

export default ({ goToNextNavItem }) => {
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { spouseAdvancedHealthCare } = userInfo || {};
  const profile = useSelector((state) => state.profile);

  const {
    firstAdvanceHealthCare,
    secondAdvanceHealthCare,
    extraNotes,
    ...restSpouseAdvancedHealthCare
  } = spouseAdvancedHealthCare || {};

  const { register, setValue, errors, handleSubmit, getValues } = useForm({
    resolver: yupResolver(advancedHealthCareSchema),
    defaultValues: {
      ...restSpouseAdvancedHealthCare,
      firstAdvanceHealthCare: firstAdvanceHealthCare || profile.name,
      secondAdvanceHealthCare: secondAdvanceHealthCare || "",
      extraNotes: extraNotes || "",
    },
  });

  const onSavedNameSubmit = ({ name, data }) => {
    if (name === "firstAdvanceHealthCare") {
      setValue("firstAdvanceHealthCare", `${data.firstName} ${data.lastName}`);
    }
    if (name === "secondAdvanceHealthCare") {
      setValue("secondAdvanceHealthCare", `${data.firstName} ${data.lastName}`);
    }
  };

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`${itemIndex}`, `${savedName.firstName} ${savedName.lastName}`);
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  const dispatch = useDispatch();
  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onAdvancedHealthCareSubmit = (data) => {
    dispatch(
      formActionCreators.postFormData({
        spouseAdvancedHealthCare: data,
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        spouseAdvancedHealthCare: data,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const { userInformation } = userInfo || {};
  const { spouseDetails } = userInformation || {};
  const spouseName =
    `${spouseDetails?.firstName || ""} ${
      spouseDetails?.lastName || ""
    }`.trim() || "Spouse";

  return (
    <div className="advanced-healthcare-wrapper">
      <FormCard>
        <span className="form-header-main-title">{`${getOnlyFirstNameFromName(
          spouseName
        )}'s Advanced health care Directive`}</span>
        <span className="form-title-desc">
          The Advanced Health Care Directive is probably one of the most
          important documents included in this package. Your Health Care Agent
          is someone you choose to speak for you when you are unable too. It may
          be in a coma or other incapacitation. You have a variety of choices
          regarding burial, medical care, medical records, organ donations, and
          other end of life choices. Most people choose to have their Agents
          have discretion in talking with you and making the choices for you. If
          there are specific choices you would like to address, please note them
          in the text box below. Share any thoughts or concerns as well. After
          the drafts are provided, we can then also make any changes to make
          sure every issue is taken care of the way you want.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onAdvancedHealthCareSubmit)}>
        <FormCard>
          <span className="form-header-title">
            First advanced healthcare directive agent
          </span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "firstAdvanceHealthCare"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "firstAdvanceHealthCare")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "firstAdvanceHealthCare")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Spouse first advance healthcare directive agent"
                name="firstAdvanceHealthCare"
                error={errors.firstAdvanceHealthCare?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("firstAdvanceHealthCare")}
                onKeyPress={() => setOpenNewSavedName("firstAdvanceHealthCare")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "firstAdvanceHealthCare" && (
          <NewSavedName
            title="New Advanced Healthcare Directive"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">
            Second advance healthcare directive agent
          </span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "secondAdvanceHealthCare"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "secondAdvanceHealthCare")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "secondAdvanceHealthCare")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Spouse Spouse second advance healthcare directive agent"
                name="secondAdvanceHealthCare"
                error={errors.secondAdvanceHealthCare?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("secondAdvanceHealthCare")}
                onKeyPress={() =>
                  setOpenNewSavedName("secondAdvanceHealthCare")
                }
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        <FormCard>
          <span className="form-header-title">
            Please make any notes or concerns and you and your attorney can
            discuss them together.
          </span>
          <div className="form-controls">
            <FormInput
              type="textarea"
              rows={6}
              name="extraNotes"
              error={errors?.extraNotes?.message || ""}
              inputRef={register()}
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        {openNewSavedName === "secondAdvanceHealthCare" && (
          <NewSavedName
            title="New Advanced Healthcare Directive"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
