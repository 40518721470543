/* eslint-disable no-console */
import React, { useState } from "react";

import Button from "../../../components/Button";
import API from "../../../services/api";

import DotLoader from "../../../components/DotLoader";
import FormError from "../../../components/FormError";
import "./GetInTouch.scss";

export default () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [messageError, setMessageError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formValidationMessage, setFormValidationMessage] = useState("");
  const [contactFormState, setContactFormState] = useState({
    successMessage: "",
    errorMessaage: "",
    isLoading: false,
  });

  const validateEmail = (mail) => {
    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (pattern.test(mail)) {
      return true;
    }
    return false;
  };

  const sendGetStartedMail = () => {
    const isEmailValid = validateEmail(email);
    if (!message) {
      setMessageError("* Message is required");
    }
    if (!email) {
      setEmailError("* Email is required");
    }
    if (!isEmailValid) {
      setEmailError("Email is not valid");
    }
    if (message && email && isEmailValid) {
      setEmailError("");
      setMessageError("");
      setContactFormState({
        ...contactFormState,
        isLoading: true,
        successMessage: "",
        errorMessaage: "",
      });
      setFormValidationMessage("");
      API.post("/contact-mail", {
        message,
        email,
      })
        .then(() => {
          setContactFormState({
            ...contactFormState,
            isLoading: false,
            successMessage: "Email sent successfully",
            errorMessaage: "",
          });
          setEmail("");
          setMessage("");
        })
        .catch((error) => {
          setContactFormState({
            ...contactFormState,
            isLoading: false,
            successMessage: "",
            errorMessaage: error.response?.data?.message,
          });
        });
    }
  };

  return (
    <div className="get-in-touch">
      <div className="get-in-touch-left">
        <span className="git-tag">How can we help you ?</span>
        <span className="git-desc">Want to get in touch?</span>
        <span className="git-desc-desc">
          ForLivingTrust uses technology to save you time and money, but it does
          not replace the person to person contact with a lawyer to ensure a
          quality plan. You can always call and talk to a HUMAN before, during,
          and after your Trust is complete. If you currently have any questions
          or would like to chat with a human or a lawyer before starting, please
          call or email today.
        </span>
        <span className="git-phone-help">Call for help</span>
        <span className="git-phone-help-no">1-833-443-8878</span>
      </div>
      <div className="git-form">
        {(formValidationMessage || contactFormState.errorMessaage) && (
          <div className="git-form-validation">
            {formValidationMessage || contactFormState.errorMessaage}
          </div>
        )}
        <span className="git-form-label">
          Message * {messageError && <FormError error={messageError} />}
        </span>
        <textarea
          placeholder="Type your message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={`${messageError ? "text-area-error" : ""}`}
        />
        <span className="git-form-label">
          Email * {emailError && <FormError error={emailError} />}
        </span>
        <div className="git-form-input-submit">
          <input
            type="text"
            placeholder="Type your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${emailError ? "input-error" : ""}`}
          />
          <Button onClick={sendGetStartedMail}>
            Get started {contactFormState.isLoading && <DotLoader />}
          </Button>
        </div>
      </div>
    </div>
  );
};
