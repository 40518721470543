/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { useHistory } from "react-router-dom";

import Footer from "../Footer";
import Divider from "../Divider";
import Button from "../Button";
import logo from "../../assets/images/logo.svg";
import "./AuthWrapper.scss";

export default ({ children, page }) => {
  const history = useHistory();

  const onLogoClick = () => {
    window.location = "/";
  };

  const getFooterButtons = () => {
    if (page === "signin") {
      return (
        <>
          <a href="/#about" tabIndex={-1}>
            <Button variant="secondary">More info</Button>
          </a>
          <Button onClick={() => history.push("/signup")}>Sign Up</Button>
        </>
      );
    }
    if (page === "signup" || page === "signupconfirm") {
      return (
        <>
          <a href="/#about" tabIndex={-1}>
            <Button variant="secondary">More info</Button>
          </a>
          <Button onClick={() => history.push("/signin")}>Sign In</Button>
        </>
      );
    }
    return null;
  };
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-wrapper">
        <img
          src={logo}
          alt="Living Trust"
          onClick={onLogoClick}
          onKeyPress={onLogoClick}
        />
        {children}
      </div>
      <Divider />
      <div className="auth-wrapper-footer-wrapper">
        <Footer buttons={getFooterButtons()} />
      </div>
    </div>
  );
};
