import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NON_SECURE_API from "../../services/non-secure-api";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import AuthWrapper from "../../components/AuthWrapper";
import "./ResetPassword.scss";
import "../ForgotPassword/ForgotPassword.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("* Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Password must contain atleast 8 character with an upper case letter, a numeric character, and a special character."
    ),
  confirmPassword: yup
    .string()
    .required("* Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Password must contain atleast 8 character with an upper case letter, a numeric character, and a special character."
    ),
});

export default () => {
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const query = useQuery();
  const token = query.get("token");

  const history = useHistory();

  const [resetPasswordState, setResetPasswordState] = useState({
    isLoading: false,
    errorMessage: "",
    successMessage: "",
  });

  const onResetPasswordSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setResetPasswordState({
        ...resetPasswordState,
        errorMessage: "Password and Confirm Password does not match",
      });
      return "";
    }
    setResetPasswordState({
      ...resetPasswordState,
      isLoading: true,
      errorMessage: "",
      successMessage: "",
    });

    NON_SECURE_API.post("/auth/resetpassword", {
      token,
      password: data.password,
    })
      .then(() => {
        setResetPasswordState({
          ...resetPasswordState,
          isLoading: false,
          errorMessage: "",
          successMessage: `Your password is changed successfully `,
        });
        setValue("password", "");
        setValue("confirmPassword", "");
      })
      .catch((error) => {
        setResetPasswordState({
          ...resetPasswordState,
          isLoading: false,
          errorMessage: error.response.data.errorMessage,
          successMessage: "",
        });
      });
    return "";
  };

  const onPassowordSuccessClick = () => {
    history.push("/signin");
  };

  return (
    <AuthWrapper page="signup">
      <div className="forgotpassword-title">Forgot your password?</div>
      {resetPasswordState.successMessage && (
        <div className="forgotpassword-success">
          {resetPasswordState.successMessage}. Please{" "}
          <span
            className="highlight-text"
            role="button"
            tabIndex={-1}
            onClick={onPassowordSuccessClick}
            onKeyPress={onPassowordSuccessClick}
          >
            click here
          </span>{" "}
          to go to Signin Page
        </div>
      )}
      {resetPasswordState.errorMessage && (
        <div className="forgotpassword-error">
          {resetPasswordState.errorMessage}
        </div>
      )}
      {!resetPasswordState.successMessage && (
        <form
          onSubmit={handleSubmit(onResetPasswordSubmit)}
          className="forgotpasswprd-form"
        >
          <span>Enter a new password for your account.</span>
          <div className="forgotpassword-email-input">
            <FormInput
              className="form-input"
              label="New password*"
              type="password"
              name="password"
              error={errors.password?.message}
              inputRef={register}
            />
            <FormInput
              className="form-input"
              label="Re enter new password*"
              type="password"
              name="confirmPassword"
              error={errors.confirmPassword?.message}
              inputRef={register}
            />
          </div>
          <Button
            className="forgotpassword-button"
            type="submit"
            isLoading={resetPasswordState.isLoading}
          >
            Submit
          </Button>
          <div className="forgotpassword-contact">
            Need help? <a href="/#contact">Contact us</a>
          </div>
        </form>
      )}
    </AuthWrapper>
  );
};
