export default [
  {
    id: 1,
    name: "Tangible personal property transactions",
  },
  {
    id: 2,
    name: "Real property transactions",
  },
  {
    id: 3,
    name: "Stock and bond transactions",
  },
  {
    id: 4,
    name: "Business operating transactions",
  },
  {
    id: 5,
    name: "Insurance and annuity transactions",
  },
  {
    id: 6,
    name: "Commodity and option transactions",
  },
  {
    id: 7,
    name: "Banking and other financial institution transactions",
  },
  {
    id: 8,
    name: "Personal and family maintenance",
  },
  {
    id: 9,
    name: "Estate, trust, and other beneficiary transactions",
  },
  {
    id: 10,
    name: "Retirement plan transactions",
  },
  {
    id: 11,
    name:
      "Benefits from Social Security, Medicare, Medicaid, and other governmental programs, or civil or military service",
  },
  {
    id: 12,
    name: "Tax matters",
  },
];
