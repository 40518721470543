/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ children, ...restProps }) => {
  const isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };
  return (
    <Route
      {...restProps}
      render={() => {
        if (!isAuthenticated()) {
          return <Redirect to="/signin" />;
        }
        return children;
      }}
    />
  );
};
