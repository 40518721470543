import React from "react";
import "./DotLoader.css";

export default function DotLoader() {
  return (
    <div className="dot-loader">
      <span> &bull;</span>
      <span> &bull;</span>
      <span> &bull;</span>
    </div>
  );
}
