import React from "react";
import FormError from "../FormError";

export default ({
  inputRef,
  name,
  options,
  className,
  label,
  error,
  onChange,
}) => (
  <div className={`forminput-wrapper ${className}`}>
    <span className="forminput-label">
      {label || ""} {error && <FormError error={error} />}
    </span>
    <select
      ref={inputRef}
      name={name}
      className="text-input"
      onChange={onChange}
    >
      {options.map((option) => (
        <option value={option.value}>{option.displayText}</option>
      ))}
    </select>
  </div>
);
