/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";

import NewSavedName from "../../../components/NewSavedName";
import FormCard from "../../../components/FormCard";
import FormRadio from "../../../components/FormRadio";
import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import AddMoreButton from "../../../components/AddMoreButton";
import { formActionCreators } from "../../../actions/form.actions";
import { getFullChildren } from "../../../helpers";
import "./Benificiaries.scss";

const benificiariesSplitSchema = {
  name: yup.string(),
  address: yup.string(),
  appartment: yup.string(),
  city: yup.string(),
  county: yup.string(),
  state: yup.string(),
  zipCode: yup
    .number()
    .nullable(true)
    .transform((_, val) => Number(val || null)),
  email: yup.string().email("Email is not valid"),
  phone: yup.string(),
  splitPercent: yup
    .number()
    .nullable(true)
    .transform((_, val) => Number(val) || null),
};

const benificiariesSchema = yup.object().shape({
  isChildrenYourBenificiaries: yup.string(),
  isEqualSplit: yup
    .string()
    .when("isChildrenYourBenificiaries", (isChildrenYourBenificiaries) => {
      if (isChildrenYourBenificiaries === "yes")
        return yup.string().required("* This field is required");
      return yup.string();
    }),
  childBenificiariesSplit: yup
    .array()
    .of(yup.object().shape(benificiariesSplitSchema)),
  extraBenificiariesSplit: yup
    .array()
    .of(yup.object().shape(benificiariesSplitSchema)),
});

export default ({ goToNextNavItem }) => {
  const dispatch = useDispatch();
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { benificiaries, userInformation } = userInfo || {};
  const { addressDetails } = userInformation || {};

  const {
    isChildrenYourBenificiaries,
    isEqualSplit,
    childBenificiariesSplit,
    extraBenificiariesSplit,
    ...restBenificiaries
  } = benificiaries || {};
  const {
    addressDetails: { noOfChildren } = {},
    childrenDetails: { children } = {},
  } = userInformation || {};

  // const getInitialChildBenificiariesSplit = ({ childrenBenificiaryOrNot }) => {
  //   const fullChildren = children;
  //   const eachChildSplit = 100 / fullChildren.length;
  //   if (childrenBenificiaryOrNot) {
  //     if (childrenBenificiaryOrNot === "yes") {
  //       return fullChildren.length > 0
  //         ? ([...new Array((fullChildren || []).length)] || []).map(
  //             (child, index) => ({
  //               name: `${fullChildren?.[index]?.firstName || ""} ${
  //                 fullChildren?.[index]?.lastName || ""
  //               }`,
  //               splitPercent: eachChildSplit,
  //             })
  //           )
  //         : [];
  //     }
  //   } else {
  //     return fullChildren.length > 0
  //       ? ([...new Array((fullChildren || []).length)] || []).map(
  //           (child, index) => ({
  //             name: `${fullChildren?.[index]?.firstName || ""} ${
  //               fullChildren?.[index]?.lastName || ""
  //             }`,
  //             splitPercent: eachChildSplit,
  //           })
  //         )
  //       : [];
  //   }
  //   return [];
  // };

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(benificiariesSchema),
    mode: "onChange",
    defaultValues: {
      ...restBenificiaries,
      isChildrenYourBenificiaries:
        isChildrenYourBenificiaries || addressDetails.isChildrenAvailable,
      isEqualSplit: isEqualSplit || "yes",
      childBenificiariesSplit,
      extraBenificiariesSplit,
    },
  });

  const watchIsChildrenYourBenificiaries = watch("isChildrenYourBenificiaries");
  const watchisEqualSplit = watch("isEqualSplit");

  const {
    fields: childFields,
    remove: childFieldsRemove,
    append: childFieldsappend,
  } = useFieldArray({
    control,
    name: "childBenificiariesSplit",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "extraBenificiariesSplit",
  });

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(
      `benificiariesSplit[${itemIndex}].name`,
      `${savedName.firstName} ${savedName.lastName}` || ""
    );
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        benificiaries: data,
      })
    );
  };

  const onAddNewBenificiaryClick = () => {
    setOpenNewSavedName(true);
  };

  const addOrModifyBenificiaries = (newChildren) => {
    const newChildrenlength = (newChildren || []).length;
    const eachChildSplit = 100 / newChildrenlength;
    const newChildBenificiaries = (newChildren || []).map((child) => ({
      name: `${child?.firstName || ""} ${child.lastName || ""}`,
      splitPercent: eachChildSplit,
    }));

    dispatch(
      formActionCreators.postFormData({
        benificiaries: {
          ...benificiaries,
          childBenificiariesSplit: newChildBenificiaries,
        },
      })
    );
    (newChildBenificiaries || []).forEach((newChild, index) => {
      childFieldsappend({
        name: newChild.name,
        splitPercent: newChild.splitPercent,
      });
    });
  };

  const onIsChildrenYourBenificiariesChange = (e) => {
    if (e.target.value === "no") {
      dispatch(
        formActionCreators.postFormData({
          benificiaries: {
            ...benificiaries,
            childBenificiariesSplit: [],
          },
        })
      );
      setValue("childBenificiariesSplit", []);
    } else {
      addOrModifyBenificiaries(children);
    }
    setValue("isEqualSplit", e.target.value);
    // triggerAutoSave();
  };

  const onSavedNameSubmit = ({ name, data }) => {
    const { address, city, county, state, zipCode, email, phone } = data;
    append({
      name: `${data.firstName} ${data.lastName}`.trim() || "",
      splitPercent: data.splitPercent,
      address,
      city,
      county,
      state,
      zipCode,
      email,
      phone,
    });
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const [showTotalMismatchError, setTotalMismatchError] = useState(false);

  const onBenificiariesSubmit = (data) => {
    const sumbitData = () => {
      const newData = {
        ...data,
        isChildrenYourBenificiaries: data.isChildrenYourBenificiaries || "no",
        isEqualSplit: data.isEqualSplit || "no",
        childBenificiariesSplit: data.childBenificiariesSplit || [],
        extraBenificiariesSplit: data.extraBenificiariesSplit || [],
      };
      dispatch(
        formActionCreators.postFormData({
          benificiaries: newData,
        })
      );
      goToNextNavItem();
    };

    const newBenSplit = [
      ...(data.childBenificiariesSplit || []),
      ...(data.extraBenificiariesSplit || []),
    ];
    if ((newBenSplit || []).length > 0) {
      const totalSplitPercent = newBenSplit.reduce(
        (sum, val) => sum + Number(val.splitPercent),
        0
      );
      if (totalSplitPercent !== 100) {
        setTotalMismatchError(true);
      } else {
        setTotalMismatchError(false);
        sumbitData();
      }
    } else {
      sumbitData();
    }
  };

  const onEqualSplitChange = (e) => {
    const newEqualSplitValue = e.target.value;
    if (newEqualSplitValue === "no") {
      (childBenificiariesSplit || []).forEach((ben, index) => {
        setValue(`childBenificiariesSplit[${index}].splitPercent`, "");
      });
      (extraBenificiariesSplit || []).forEach((ben, index) => {
        setValue(`extraBenificiariesSplit[${index}].splitPercent`, "");
      });
    }
  };

  return (
    <div className="benificiaries-wrapper">
      <FormCard>
        <span className="form-header-main-title">Beneficiaries</span>
        <span className="form-title-desc">
          To put in the simplest way, this is where you choose who gets your
          stuff when you pass away. If you are married, it would pass to the
          beneficiary when the second person dies. It is usually your children,
          but can also be charities, relatives, or the guy down the street who
          helped you when you ran out of gas that time.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onBenificiariesSubmit)}>
        {addressDetails.isChildrenAvailable === "yes" && (
          <FormCard>
            <span className="form-header-title">Child Beneficiaries</span>
            <div className="form-controls">
              <FormRadio
                name="isChildrenYourBenificiaries"
                options={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                inputRef={register}
                label="Are your beneficiaries going to be your children?"
                onChange={onIsChildrenYourBenificiariesChange}
              />
              {watchIsChildrenYourBenificiaries === "yes" && (
                <FormRadio
                  name="isEqualSplit"
                  options={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                  ]}
                  inputRef={register}
                  label="Equal split?"
                  onChange={onEqualSplitChange}
                  // onBlur={triggerAutoSave}
                />
              )}
            </div>
          </FormCard>
        )}
        <FormCard>
          {/* <span className="form-header-title">Benefits Percentage Split</span> */}
          {childFields.map((item, index) => (
            <div
              className={`form-controls-children percentage-split-texts ${
                dragOver === index ? "form-controls-children-drag-over" : ""
              }`}
              key={item.id}
              onDrop={(e) => onDropSavedNames(e, index)}
              onDragOver={(e) => onDragOverSavedNames(e, index)}
              onDragLeave={() => setDragover(null)}
            >
              <div className="benificiary-textbox">
                <FormInput
                  label={
                    addressDetails.isChildrenAvailable === "yes" ? " " : " "
                  }
                  name={`childBenificiariesSplit[${index}].name`}
                  error={
                    errors?.childBenificiariesSplit?.[index]?.name?.message ||
                    ""
                  }
                  inputRef={register()}
                  onBlur={triggerAutoSave}
                  defaultValue={item.name}
                />
              </div>
              <div className="split-percent-textbox">
                <FormInput
                  label="Percentage split"
                  name={`childBenificiariesSplit[${index}].splitPercent`}
                  error={
                    errors?.childBenificiariesSplit?.[index]?.splitPercent
                      ?.message || ""
                  }
                  inputRef={register()}
                  className="benificiaries-split"
                  onBlur={triggerAutoSave}
                  defaultValue={item.splitPercent}
                />
                <div className="split-percent-textbox-text">%</div>
              </div>
              <div className="delete-button">
                <AddMoreButton onClick={() => childFieldsRemove(index)}>
                  Delete
                </AddMoreButton>
              </div>
            </div>
          ))}
          {fields.map((item, index) => (
            <div
              className={`form-controls-children percentage-split-texts ${
                dragOver === index ? "form-controls-children-drag-over" : ""
              }`}
              key={item.id}
              onDrop={(e) => onDropSavedNames(e, index)}
              onDragOver={(e) => onDragOverSavedNames(e, index)}
              onDragLeave={() => setDragover(null)}
            >
              <div className="benificiary-textbox">
                <FormInput
                  label={
                    addressDetails.isChildrenAvailable === "yes" ? " " : " "
                  }
                  name={`extraBenificiariesSplit[${index}].name`}
                  error={
                    errors?.extraBenificiariesSplit?.[index]?.name?.message ||
                    ""
                  }
                  inputRef={register()}
                  onBlur={triggerAutoSave}
                  defaultValue={item.name}
                />
              </div>
              <div className="split-percent-textbox">
                <FormInput
                  label="Percentage split"
                  name={`extraBenificiariesSplit[${index}].splitPercent`}
                  error={
                    errors?.extraBenificiariesSplit?.[index]?.splitPercent
                      ?.message || ""
                  }
                  inputRef={register()}
                  className="benificiaries-split"
                  onBlur={triggerAutoSave}
                  defaultValue={item.splitPercent}
                />
                <div className="split-percent-textbox-text">%</div>
              </div>
              <div className="delete-button">
                <AddMoreButton onClick={() => remove(index)}>
                  Delete
                </AddMoreButton>
              </div>
            </div>
          ))}
          <AddMoreButton onClick={onAddNewBenificiaryClick}>
            Add new beneficiaries
          </AddMoreButton>
        </FormCard>
        {openNewSavedName && (
          <NewSavedName
            title="New Benificiary"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName(false)}
            type="benificiary"
          />
        )}
        <div style={{ marginTop: "20px" }}>
          {showTotalMismatchError && (
            <span className="error-message">
              Sum of Percentage split is not equal to 100%.
            </span>
          )}
        </div>
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
