import axios from "axios";

const isAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  return new Date().getTime() < expiresAt;
};

const { REACT_APP_API_URL } = process.env;

const URL = `${REACT_APP_API_URL}`;

const API = axios.create({
  baseURL: URL,
});

API.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("access_token");
    const configCopy = { ...config };
    if (isAuthenticated()) {
      configCopy.headers.authorization = `Bearer ${accessToken}`;
    } else {
      window.location.assign("/signin");
    }
    return configCopy;
  },
  (error) => Promise.reject(error)
);

export default API;
