import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NON_SECURE_API from "../../services/non-secure-api";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import AuthWrapper from "../../components/AuthWrapper";
import "./ForgotPassword.scss";

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("* email is required")
    .email("Email is not valid"),
});

export default () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const [forgotPasswordState, setForgotPasswordState] = useState({
    isLoading: false,
    errorMessage: "",
    successMessage: "",
  });

  const onForgotPasswordSubmit = (data) => {
    setForgotPasswordState({
      ...forgotPasswordState,
      isLoading: true,
      errorMessage: "",
      successMessage: "",
    });
    NON_SECURE_API.post("/auth/forgotpassword", {
      email: data.email,
    })
      .then(() => {
        setForgotPasswordState({
          ...forgotPasswordState,
          isLoading: false,
          errorMessage: "",
          successMessage: `If a LivingTrust account exists for ${data.email} an email will be sent with further instructions.`,
        });
      })
      .catch((error) => {
        console.log("erroris", error);
        setForgotPasswordState({
          ...forgotPasswordState,
          isLoading: false,
          errorMessage: error.response.data.errorMessage,
          successMessage: "",
        });
      });
  };
  return (
    <AuthWrapper page="signup">
      <div className="forgotpassword-title">Forgot your password?</div>
      {forgotPasswordState.successMessage && (
        <div className="forgotpassword-success">
          {forgotPasswordState.successMessage}
        </div>
      )}
      <form
        onSubmit={handleSubmit(onForgotPasswordSubmit)}
        className="forgotpasswprd-form"
      >
        <span>
          Enter your email address to reset your password. You may need to check
          your spam folder .
        </span>
        <FormInput
          className="form-input"
          label="Email*"
          type="email"
          name="email"
          error={errors.email?.message}
          inputRef={register}
        />
        <Button
          className="forgotpassword-button"
          type="submit"
          isLoading={forgotPasswordState.isLoading}
        >
          Submit
        </Button>
        <div className="forgotpassword-contact">
          Need help? <a href="/#contact">Contact us</a>
        </div>
      </form>
    </AuthWrapper>
  );
};
