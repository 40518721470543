const events = ["click", "keypress"];

const startSessionTimer = (sessionTime, logoutFnProp) => {
  const otherEvents = ["online", "offline", "visibilitychange"];
  let timeOutId;
  const timeInMilliSeconds = 1000 * sessionTime;
  let expiryTime = Date.now() + timeInMilliSeconds;

  const isSystemTimeChanged = () => {
    const currentTime = Date.now();
    // Ideally the difference doesn't exceed the sessionTime
    // If the difference exceeds means the system time has changed.
    return Math.abs(expiryTime - currentTime) > timeInMilliSeconds;
  };

  const isValidSession = () => {
    const currentTime = Date.now();
    return expiryTime > currentTime;
  };

  const logout = () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    logoutFnProp();
  };

  const startTimer = () => {
    timeOutId = setTimeout(() => {
      if (navigator.onLine) {
        logout();
      }
    }, timeInMilliSeconds);
  };

  const setUpdateSessionExpiryTime = () => {
    expiryTime = Date.now() + timeInMilliSeconds;
    if (timeOutId) {
      clearTimeout(timeOutId);
      startTimer();
    }
  };

  const checkExpiredSession = () => {
    if ((!isValidSession() && navigator.onLine) || isSystemTimeChanged()) {
      logout();
    }
  };

  otherEvents.forEach((event) => {
    window.addEventListener(event, checkExpiredSession);
  });

  // Resets time and changes the local storage for above events
  // changing the local storage is for resetting the time in other tabs as well
  events.forEach((event) => {
    window.addEventListener(event, () => {
      localStorage.setItem(
        "tab",
        JSON.parse(localStorage.getItem("tab"))
          ? !JSON.parse(localStorage.getItem("tab"))
          : true
      );
      setUpdateSessionExpiryTime();
    });
  });

  // listens to the events triggered by changing the values in the local storage
  // If any of the above events happened in the other tabs, local storage changes and this resets timeout in the active tab also
  window.addEventListener("storage", setUpdateSessionExpiryTime);

  startTimer();
};

export default startSessionTimer;
