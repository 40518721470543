/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import CarousalIndicators from "../../../components/CarousalIndicators";
import HeroImage1 from "../../../assets/images/heroimage1.png";
import HeroImage2 from "../../../assets/images/heroimage2.png";
import HeroImage3 from "../../../assets/images/heroimage3.png";
import "./HeroCarousal.scss";

export default () => {
  const slideImages = [
    { image: HeroImage1 },
    { image: HeroImage2 },
    { image: HeroImage3 },
  ];

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const showNextSlide = () => {
      if (activeSlideIndex < slideImages.length - 1) {
        setActiveSlideIndex(activeSlideIndex + 1);
      } else {
        setActiveSlideIndex(0);
      }
    };
    const timeInterval = setInterval(() => {
      showNextSlide();
    }, 5000);
    return () => clearInterval(timeInterval);
  }, [activeSlideIndex]);

  return (
    <div className="hero-carousal">
      <div className="hero-carousal-slide">
        {slideImages.map((slide, index) => (
          <img
            src={slide.image}
            alt=""
            className={`${
              index === activeSlideIndex
                ? "hero-carousal-slide-image-active"
                : "hero-carousal-slide-image"
            }`}
          />
        ))}
        <div className="slide-content">
          <span>Get your Living Trust done from your Living Room.</span>
          <span>
            <b>For</b> Living <b>Trust</b> makes it easy and efficient to finish
            your estate plan from your home or office.
          </span>
          <span>
            <span>
              <a href="#about" tabIndex={-1}>
                <Button
                  variant="secondary"
                  onClick={() => history.push("/#about")}
                >
                  More Info
                </Button>
              </a>
              <Button onClick={() => history.push("/form")}>Get started</Button>
            </span>
            <span />
          </span>
        </div>
        <CarousalIndicators
          activeSlideIndex={activeSlideIndex}
          setActiveSlideIndex={setActiveSlideIndex}
          slides={slideImages}
        />
      </div>
    </div>
  );
};
