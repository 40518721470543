import initProfileState from "./initProfileState";
import { profileActions } from "../actions/profile.actions";

export default {
  profile: (state = initProfileState.profile, action) => {
    const { type, payload } = action;
    switch (type) {
      case profileActions.UPDATE_PROFILE:
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    }
  },
};
