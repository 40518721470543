export default {
  userInfoState: {
    isLoading: true,
    errorMessage: "",
    userInfo: null,
    isPositingData: false,
    postedDataSuccess: false,
    postedDataError: false,
  },
  navItemsState: {
    isLoading: true,
    errorMessage: "",
    navItems: null,
  },
  savedNamesState: {
    isLoading: true,
    savedNames: null,
    errorMessage: "",
  },
  questionsState: {
    isLoading: true,
    questions: null,
    errorMessage: "",
  },
  navigationState: null,
  navigationStateWithName: null,
};
