import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormCard from "../../../../components/FormCard";
import UserInfoContinueCard from "../../../../components/UserInfoContinueCard";
import FormInput from "../../../../components/FormInput";
import NextButton from "../../../../components/NextButton";
import BackButton from "../../../../components/BackButton";
import FormRadio from "../../../../components/FormRadio";
import { formActionCreators } from "../../../../actions/form.actions";
import { checkIfNamePresentInSavedNames } from "../../../../helpers";

const childrenSchema = {
  firstName: yup.string(),
  lastName: yup.string(),
  dob: yup.string(),
};

const deceasedChildrenDetailsSchema = yup.object().shape({
  children: yup.array().of(yup.object().shape(childrenSchema)),
  isParentsOfDeceasedChildSame: yup.string().required("This field is required"),
  deceasedChildParentFirstName: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* FirstName is required");
      return yup.string();
    }),
  deceasedChildParentLastName: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* LastName is required");
      return yup.string();
    }),
  deceasedChildParentCity: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* City is required");
      return yup.string();
    }),
  deceasedChildParentCountry: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* County is required");
      return yup.string();
    }),
  deceasedChildParentState: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* State is required");
      return yup.string();
    }),
  deceasedChildZipCode: yup
    .string()
    .when("isParentsOfDeceasedChildSame", (isParentsOfDeceasedChildSame) => {
      if (isParentsOfDeceasedChildSame === "no")
        return yup.string().required("* ZipCode is required");
      return yup.string();
    }),
});

export default ({ goToNextNavItem, goBackSubItem }) => {
  const [dragOver, setDragover] = useState(null);
  const dispatch = useDispatch();
  // const profile = useSelector((state) => state.profile);
  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { userInformation } = userInfo || {};
  const { childrenDetails, deceasedChildrenDetails } = userInformation || {};
  const { deceasedChild } = childrenDetails || {};

  const defaultChildren = [...new Array(deceasedChild)].map(() => ({
    firstName: "",
    lastName: "",
    dob: "",
  }));

  const {
    children,
    isParentsOfDeceasedChildSame,
    ...restDeceasedChildrenDetails
  } = deceasedChildrenDetails || {};

  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(deceasedChildrenDetailsSchema),
    defaultValues: {
      ...restDeceasedChildrenDetails,
      children: (children || []).length > 0 ? children : defaultChildren,
      isParentsOfDeceasedChildSame: "yes",
    },
  });

  const watchIsParentsOfDeceasedChildSame = watch(
    "isParentsOfDeceasedChildSame"
  );

  const { fields } = useFieldArray({
    control,
    name: "children",
  });

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`children[${itemIndex}].firstName`, savedName.firstName || "");
    setValue(`children[${itemIndex}].lastName`, savedName.lastName || "");
    setValue(`children[${itemIndex}].dob`, savedName.dob || "");
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  const watchChildren = watch("children");

  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = () => {
    const newNames = [];
    (watchChildren || []).forEach((child) => {
      if (child.firstName || child.lastName) {
        const childFound = checkIfNamePresentInSavedNames(savedNames, {
          firstName: child.firstName,
          lastName: child.lastName,
        });
        if (!childFound) {
          newNames.push({ ...child });
        }
      }
    });
    if ((newNames || []).length > 0) {
      dispatch(formActionCreators.postSavedNames([...savedNames, ...newNames]));
    }
  };

  const onDeceasedChildDetailsSubmit = (data) => {
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          deceasedChildrenDetails: data,
        },
      })
    );
    saveNamesToSavedNames();
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          deceasedChildrenDetails: data,
        },
      })
    );
  };
  return (
    <div>
      <UserInfoContinueCard name="Please tell us about your Child[ren]" />
      <form onSubmit={handleSubmit(onDeceasedChildDetailsSubmit)}>
        <FormCard>
          <span className="form-title">Deceased Children</span>

          {fields.map((item, index) => (
            <div
              className={`form-controls-children ${
                dragOver === index ? "form-controls-children-drag-over" : ""
              }`}
              key={item.id}
              onDrop={(e) => onDropSavedNames(e, index)}
              onDragOver={(e) => onDragOverSavedNames(e, index)}
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                label="First Name"
                name={`children[${index}].firstName`}
                error={errors?.children?.[index]?.firstName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.firstName}
              />
              <FormInput
                label="Last Name"
                name={`children[${index}].lastName`}
                error={errors?.children?.[index]?.lastName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.lastName}
              />
              <FormInput
                label="DOB"
                name={`children[${index}].dob`}
                type="date"
                error={errors?.children?.[index]?.dob?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.dob}
              />
            </div>
          ))}
          <div className="form-controls">
            <FormRadio
              label="Is parents of deceased child are same?"
              name="isParentsOfDeceasedChildSame"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        {watchIsParentsOfDeceasedChildSame === "no" && (
          <FormCard>
            <span className="form-title">Parent of deceased child</span>
            <div className="form-controls">
              <div className="form-controls-section">
                <FormInput
                  label="First Name"
                  name="deceasedChildParentFirstName"
                  error={errors.deceasedChildParentFirstName?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="Last Name"
                  name="deceasedChildParentLastName"
                  error={errors.deceasedChildParentLastName?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
              </div>
              <div className="form-controls-section">
                <FormInput
                  label="City"
                  name="deceasedChildParentCity"
                  error={errors.deceasedChildParentCity?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="County"
                  name="deceasedChildParentCountry"
                  error={errors.deceasedChildParentCountry?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
              </div>
              <div className="form-controls-section">
                <FormInput
                  label="State"
                  name="deceasedChildParentState"
                  error={errors.deceasedChildParentState?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="Zip Code"
                  name="deceasedChildZipCode"
                  error={errors.deceasedChildZipCode?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
              </div>
            </div>
          </FormCard>
        )}
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBackSubItem} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
