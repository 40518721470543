/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";

import Play from "../../assets/images/play.svg";
import Pause from "../../assets/images/pause.svg";
import FullScreen from "../../assets/images/FullScreen.svg";
import BigPlay from "../../assets/images/bigPlay.svg";
import "./Video.scss";

export default ({ mp4source }) => {
  const [playState, setPlayState] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(null);
  const [volume, setVolume] = useState(null);
  const [showControlsState, setShowControlsState] = useState(true);

  const onSeekChange = (e) => {
    const video = document.getElementById("video");
    setCurrentTime(e.target.value);
    video.currentTime = e.target.value;
  };

  const togglePlay = () => {
    const video = document.getElementById("video");
    if (video.paused || video.ended) {
      video.play();
    } else {
      video.pause();
    }
  };

  const onVideoPlay = () => {
    setPlayState(true);
    setShowControlsState(true);
  };

  const onVideoPause = () => {
    setPlayState(false);
    setShowControlsState(true);
  };

  const initializeVideo = () => {
    const video = document.getElementById("video");
    const videoDuration = Math.round(video.duration);
    setDuration(videoDuration);
    const videoVolume = video.volume;
    setVolume(videoVolume);
  };

  const updateTimeElapsed = () => {
    const video = document.getElementById("video");
    const videoCurrentTime = Math.round(video.currentTime);
    setCurrentTime(videoCurrentTime);
  };

  const updateVolume = () => {
    const video = document.getElementById("video");
    const videoVolume = video.volume;
    setVolume(videoVolume);
  };

  useEffect(() => {
    const video = document.getElementById("video");
    video.addEventListener("play", onVideoPlay);
    video.addEventListener("pause", onVideoPause);
    video.addEventListener("loadedmetadata", initializeVideo);
    video.addEventListener("timeupdate", updateTimeElapsed);
    video.addEventListener("volumechange", updateVolume);

    return () => {
      video.removeEventListener("play", onVideoPlay);
      video.removeEventListener("pause", onVideoPause);
      video.removeEventListener("loadedmetadata", initializeVideo);
      video.removeEventListener("timeupdate", updateTimeElapsed);
      video.removeEventListener("volumechange", updateVolume);
    };
  }, []);

  const setVideoVolume = (value) => {
    const video = document.getElementById("video");
    video.volume = value;
  };

  const toggleFullScreen = () => {
    function requestFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }

    const videoPlayer = document.getElementById("video-player");

    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.documentElement.webkitRequestFullScreen;

      if (document.fullscreenEnabled) {
        requestFullscreen(videoPlayer);
      }
    }
  };

  const showControls = () => {
    setShowControlsState(true);
  };

  const hideControls = () => {
    const video = document.getElementById("video");
    if (video.paused) {
      return;
    }
    setShowControlsState(false);
  };

  return (
    <div
      className="video-player"
      id="video-player"
      onMouseEnter={showControls}
      onMouseLeave={hideControls}
    >
      <video id="video">
        <source src={mp4source} type="video/mp4" />
        This browser does not support Video
      </video>
      <div className="video-big-play-pause" onClick={togglePlay}>
        <img
          src={BigPlay}
          alt="play"
          className={playState ? "hide-video-big-play-icon" : ""}
        />
      </div>
      <div
        className={`video-controls ${
          !showControlsState ? "hide-video-controls" : ""
        }`}
      >
        <div className="video-play-pause">
          <div className="play-pause-button" onClick={togglePlay}>
            <img src={playState ? Pause : Play} alt="play" />
          </div>
        </div>
        <div className="video-progress-sound">
          <div className="video-progress">
            <progress
              id="progress-bar"
              value={currentTime}
              min="0"
              max={duration}
            />
            <input
              className="seek"
              id="seek"
              value={currentTime}
              min="0"
              max={duration}
              type="range"
              step="1"
              onChange={onSeekChange}
            />
          </div>
          <div className="video-volume-fullscreen">
            <div className="video-volume">
              {[20, 40, 60, 80, 100].map((item) => (
                <span
                  className={`video-volume-bar ${
                    item <= volume * 100 ? "video-volume-bar-active" : ""
                  }`}
                  key={item}
                  style={{ height: `${item + 20}%` }}
                  onClick={() => setVideoVolume(item / 100)}
                />
              ))}
            </div>
            <div onClick={toggleFullScreen} className="video-fullscreen">
              <img src={FullScreen} alt="FullScreen" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
