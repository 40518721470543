import initFormState from "./initFormState";
import { formActions } from "../actions/form.actions";

export default {
  userInfoState: (state = initFormState.userInfoState, action) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.FETCH_FORM_DATA:
        return {
          ...state,
          isLoading: true,
          errorMessage: "",
        };
      case formActions.FETCH_FORM_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          userInfo: payload,
          errorMessage: "",
        };
      case formActions.FETCH_FORM_DATA_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          userInfo: null,
        };
      case formActions.POST_FORM_DATA:
        return {
          ...state,
          isPositingData: true,
          postedDataSuccess: false,
        };
      case formActions.POST_FORM_DATA_SUCCESS:
        return {
          ...state,
          isPositingData: false,
          postedDataSuccess: true,
          userInfo: payload,
        };
      default:
        return state;
    }
  },
  navigationState: (state = initFormState.navigationState, action) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.UPDATE_NAVIGATION_STATE:
        return payload;
      default:
        return state;
    }
  },
  navigationStateWithName: (
    state = initFormState.navigationStateWithName,
    action
  ) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.UPDATE_NAVIGATION_STAT_WITH_NAME:
        return payload;
      default:
        return state;
    }
  },
  navItemsState: (state = initFormState.navItemsState, action) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.GET_NAV_ITEMS:
        return {
          ...state,
          isLoading: true,
          errorMessage: "",
        };
      case formActions.GET_NAV_ITEMS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          navItems: payload || { activeItem: 1 },
          errorMessage: "",
        };
      case formActions.GET_NAV_ITEMS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          navItems: null,
        };
      case formActions.POST_NAV_ITEMS:
        return {
          ...state,
          navItems: {
            ...state.navItems,
            activeItem: payload.activeItem
              ? payload.activeItem
              : state.navItems.activeItem,
            completedItems: payload.completedItems
              ? payload.completedItems
              : state.navItems.completedItems,
          },
        };
      default:
        return state;
    }
  },
  savedNamesState: (state = initFormState.savedNamesState, action) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.GET_SAVED_NAMES:
        return {
          ...state,
          isLoading: true,
          errorMessage: "",
        };
      case formActions.GET_SAVED_NAMES_SUCCESS:
        return {
          ...state,
          isLoading: false,
          savedNames: payload || [],
          errorMessage: "",
        };
      case formActions.GET_SAVED_NAMES_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          savedNames: [],
        };
      case formActions.POST_SAVED_NAMES:
        return {
          ...state,
          savedNames: payload,
        };
      default:
        return state;
    }
  },
  questionsState: (state = initFormState.questionsState, action) => {
    const { type, payload } = action;
    switch (type) {
      case formActions.GET_QUESTIONS:
        return {
          ...state,
          isLoading: true,
          errorMessage: "",
        };
      case formActions.GET_QUESTIONS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          questions: payload || [],
          errorMessage: "",
        };
      case formActions.GET_QUESTIONS_ERROR:
        return {
          ...state,
          isLoading: false,
          errorMessage: payload,
          questions: [],
        };
      case formActions.POST_QUESTIONS:
        return {
          ...state,
          questions: payload,
        };
      default:
        return state;
    }
  },
};
