/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NON_SECURE_API from "../../services/non-secure-api";
import AuthWrapper from "../../components/AuthWrapper";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import FormError from "../../components/FormError";
import "./Signup.scss";

const signUpSchema = yup.object().shape({
  firstName: yup.string().required("* Firstname is required"),
  lastName: yup.string(),
  email: yup
    .string()
    .required("* email is required")
    .email("Email is not valid"),
  password: yup
    .string()
    .required("* Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Password must contain atleast 8 character with an upper case letter, a numeric character, and a special character."
    ),
});

export default () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(signUpSchema),
  });
  const [signupState, setSignupState] = useState({
    isLoading: false,
    errorMessage: "",
  });

  const history = useHistory();

  const onSigninClick = () => {
    history.push("/signin");
  };

  const onSignupSubmit = (data) => {
    setSignupState({ ...signupState, isLoading: true, errorMessage: "" });
    NON_SECURE_API.post("/auth/signup", {
      email: data.email,
      password: data.password,
      name: `${data.firstName} ${data.lastName}`,
    })
      .then((response) => {
        setSignupState({ ...signupState, isLoading: false, errorMessage: "" });
        history.push(`/signupconfirm/?email=${response.data.email}`);
      })
      .catch((error) => {
        setSignupState({
          ...signupState,
          isLoading: false,
          errorMessage: error.response.data.errorMessage,
        });
      });
  };

  return (
    <AuthWrapper page="signup">
      <span className="signup-text">
        Living Trust from your Living Room, saving you time and money.
      </span>
      <form className="signup-form" onSubmit={handleSubmit(onSignupSubmit)}>
        <div className="signup-form-error">
          {signupState.errorMessage && (
            <FormError error={signupState.errorMessage} />
          )}
        </div>
        <div className="signup-form-names">
          <FormInput
            label="First name*"
            name="firstName"
            error={errors.firstName?.message}
            inputRef={register}
          />
          <FormInput
            label="Last name"
            name="lastName"
            inputRef={register}
            error={errors.lastName?.message}
          />
        </div>
        <FormInput
          label="Email*"
          type="email"
          name="email"
          error={errors.email?.message}
          inputRef={register}
        />
        <FormInput
          label="Password*"
          type="password"
          name="password"
          error={errors.password?.message}
          inputRef={register}
        />
        <div className="signup-policy">
          By clicking Agree & Join, you agree to the <span>User Agreement</span>
          , <span>Privacy Policy</span>, and <span>Cookie Policy</span>.
        </div>
        <Button
          className="signup-button"
          type="submit"
          isLoading={signupState.isLoading}
        >
          Signup
        </Button>
        <div className="signup-signin">
          Already on LivingTrust?{" "}
          <span
            onClick={onSigninClick}
            role="button"
            onKeyPress={onSigninClick}
            tabIndex={0}
          >
            Sign In
          </span>
        </div>
      </form>
    </AuthWrapper>
  );
};
