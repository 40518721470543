/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  formatPhoneNumber,
  getOnlyFirstNameFromName,
  checkIfNamePresentInSavedNames,
  getFirstAndLastNameFromFullName,
  getUpdatedSavedNames,
} from "../../../../helpers";
import NextButton from "../../../../components/NextButton";
import FormRadio from "../../../../components/FormRadio";
import FormInput from "../../../../components/FormInput";
import FormCard from "../../../../components/FormCard";
import { formActionCreators } from "../../../../actions/form.actions";
import usePrevious from "../../../../hooks/usePrevious";
import Alert from "../../../../components/Alert";
import "./AddressDetails.scss";
import "../../Form.scss";

const addressDetailsSchema = yup.object().shape({
  address: yup.string().required("* Address is required"),
  city: yup.string().required("* City is required"),
  county: yup.string().required("* County is required"),
  state: yup.string().required("* State is required"),
  zipCode: yup
    .number()
    .typeError("Zip Code must be a number")
    .positive("Zip Code must be positive number")
    .required("* ZipCode is required"),
  phone: yup.string().required("* Phone is required"),
  dob: yup.string().required("* DOB is required"),
  gender: yup.string().required("* Gender is required"),
  isUsCitizen: yup.string().required("This field is required"),
  maritalStatus: yup.string().required("Status is required"),
  properties: yup
    .string()
    .nullable(true)
    .transform((_, val) => val || ""),
  isChildrenAvailable: yup.string().required("Children is required"),
  noOfChildren: yup
    .string()
    .when("isChildrenAvailable", (isChildrenAvailable) => {
      if (isChildrenAvailable === "yes")
        return yup
          .number()
          .typeError("No of Children must be a number")
          .required("* No of Children is required");
      return yup.string();
    }),
});

export default ({
  setActiveUserInfomSubItems,
  activeUserInformSubItems,
  goToNextNavItem,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfoState = useSelector((state) => state.userInfoState);
  const navigationStateWithName = useSelector(
    (state) => state.navigationStateWithName
  );
  const profile = useSelector((state) => state.profile);
  const { userInfo } = userInfoState;
  const { userInformation, benificiaries, assetsDetails } = userInfo || {};
  const { addressDetails, childrenDetails } = userInformation || {};
  const {
    gender,
    isUsCitizen,
    maritalStatus,
    isChildrenAvailable,
    ...restAddressDetails
  } = addressDetails || {};

  const prevMaritalStatus = maritalStatus;

  const getInitialMaritalStatus = () => {
    if (maritalStatus) {
      return maritalStatus;
    }
    if (location?.state?.maritalStatus) {
      return location?.state?.maritalStatus;
    }
    return "single";
  };

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addressDetailsSchema),
    defaultValues: {
      ...restAddressDetails,
      gender: gender || "male",
      isUsCitizen: isUsCitizen || "yes",
      maritalStatus: getInitialMaritalStatus(),
      isChildrenAvailable: isChildrenAvailable || "no",
    },
  });

  const watchIsChildrenAvailable = watch("isChildrenAvailable");
  const watchMaritalStatus = watch("maritalStatus");

  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = (data) => {
    const firstAndLastNames = getFirstAndLastNameFromFullName(profile.name);
    const {
      address,
      city,
      county,
      state,
      zipCode,
      phone,
      dob,
      gender: Gender,
    } = data;
    const newSavedName = {
      firstName: firstAndLastNames.firstName,
      lastName: firstAndLastNames.lastName,
      address,
      city,
      county,
      state,
      zipCode,
      phone,
      dob,
      Gender,
    };
    const newSavedNames = getUpdatedSavedNames(savedNames, newSavedName);
    dispatch(formActionCreators.postSavedNames(newSavedNames));
  };

  const getUpdatedNavItemsForSingleWithNoCHildren = () =>
    (navigationStateWithName || []).filter(
      (navState) => navState.id !== 5 && navState.id !== 7 && navState.id !== 9
    );

  const getUpdatedNavItemsForSingleWithChildren = () =>
    (navigationStateWithName || []).filter(
      (navState) => navState.id !== 7 && navState.id !== 9
    );

  const getUpdatedNavItemsForMarriedWithChildren = () =>
    navigationStateWithName;

  const getUpdatedNavItemsForMarriedWithNoChildren = () =>
    (navigationStateWithName || []).filter((navState) => navState.id !== 5);

  const onUserInformationSubmit = (data) => {
    const newData = {
      ...data,
      noOfChildren:
        isChildrenAvailable === "yes" ? parseInt(data.noOfChildren, 10) : "",
    };
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          addressDetails: newData,
        },
      })
    );
    saveNamesToSavedNames(data);
    if (watchMaritalStatus === "single" && watchIsChildrenAvailable === "yes") {
      dispatch(
        formActionCreators.updateNavigationState([
          ...getUpdatedNavItemsForSingleWithChildren(),
        ])
      );
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "childrenDetails",
      ]);
    } else if (
      watchMaritalStatus === "single" &&
      watchIsChildrenAvailable === "no"
    ) {
      dispatch(
        formActionCreators.updateNavigationState([
          ...getUpdatedNavItemsForSingleWithNoCHildren(),
        ])
      );
      setActiveUserInfomSubItems([...activeUserInformSubItems, "videoDetails"]);
    } else if (
      watchMaritalStatus === "married" &&
      watchIsChildrenAvailable === "no"
    ) {
      dispatch(
        formActionCreators.updateNavigationState([
          ...getUpdatedNavItemsForMarriedWithNoChildren(),
        ])
      );
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "spouseDetails",
      ]);
    } else {
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "spouseDetails",
      ]);
      dispatch(
        formActionCreators.updateNavigationState([
          ...getUpdatedNavItemsForMarriedWithChildren(),
        ])
      );
    }
  };

  const triggerAutoSave = () => {
    const data = getValues();
    const newData = {
      ...data,
      noOfChildren:
        isChildrenAvailable === "yes" ? parseInt(data.noOfChildren, 10) : "",
    };
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          addressDetails: newData,
        },
      })
    );
  };

  useEffect(() => {
    triggerAutoSave();
  }, []);

  const verifyAndUpdateMaritalStatus = (e) => {
    const newMaritalStatus = e.target.value;
    if (prevMaritalStatus === "married" && newMaritalStatus === "single") {
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            spouseDetails: {},
          },
        })
      );
    }
    triggerAutoSave();
  };

  const currentProperties = restAddressDetails.properties;
  const [showPropertiesAlert, setShowPropertiesAlert] = useState(null);

  const updatePropertyDetailsInNextPages = (newProperties) => {
    const currentPropertyDetails = assetsDetails.propertyDetails;
    let newPropertyDetails;
    if (currentPropertyDetails.length > newProperties) {
      newPropertyDetails = (currentPropertyDetails || []).slice(
        0,
        currentPropertyDetails.length
      );
    } else {
      newPropertyDetails = [
        ...currentPropertyDetails,
        ...Array.from({
          length: newProperties - currentPropertyDetails.length,
        }).fill({
          address: "",
          city: "",
          state: "",
          zipCode: "",
          fileName: "",
        }),
      ];
    }
    dispatch(
      formActionCreators.postFormData({
        assetsDetails: {
          propertyDetails: newPropertyDetails,
        },
        userInformation: {
          addressDetails: {
            properties: newProperties,
          },
        },
      })
    );
  };

  const verifyAndUpdateProperties = (e) => {
    const newProperties = e.target.value;
    if (
      Number(currentProperties) !== Number(newProperties) &&
      newProperties &&
      currentProperties
    ) {
      setShowPropertiesAlert(newProperties);
    } else {
      updatePropertyDetailsInNextPages(newProperties);
    }
  };

  const onPropertiesChangeYesClick = () => {
    updatePropertyDetailsInNextPages(showPropertiesAlert);
    setShowPropertiesAlert(null);
  };

  const onPropertiesChangeNoClick = () => {
    setValue("properties", currentProperties);
    setShowPropertiesAlert(null);
  };

  const currentNoOfChildren = restAddressDetails.noOfChildren;
  const [showChildrenAlert, setShowChildrenAlert] = useState();

  const updateChildrenDetailsInNextPages = (newNoOfChildren) => {
    const currentChildrenDetails = childrenDetails;
    let newChildrenDetails;
    if (currentChildrenDetails.children.length > newNoOfChildren) {
      newChildrenDetails = {
        ...currentChildrenDetails,
        children: (currentChildrenDetails.children || []).slice(
          0,
          newNoOfChildren
        ),
      };
    } else {
      newChildrenDetails = {
        ...currentChildrenDetails,
        children: [
          ...currentChildrenDetails.children,
          ...Array.from({
            length: newNoOfChildren - currentChildrenDetails.children.length,
          }).fill({
            firstName: "",
            lastName: "",
            dob: "",
          }),
        ],
      };
    }
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          childrenDetails: newChildrenDetails,
        },
      })
    );
  };

  const verifyAndUpdateChildren = (e) => {
    const newNoOfChildren = e.target.value;
    if (
      Number(currentNoOfChildren) !== Number(newNoOfChildren) &&
      newNoOfChildren &&
      currentNoOfChildren
    ) {
      setShowChildrenAlert(newNoOfChildren);
    } else {
      updateChildrenDetailsInNextPages(newNoOfChildren);
    }
  };

  const onChildrenChangeYesClick = () => {
    updateChildrenDetailsInNextPages(showChildrenAlert);
    setShowChildrenAlert(null);
  };
  const onChildrenChangeNoClick = () => {
    setValue("noOfChildren", currentNoOfChildren);
    setShowChildrenAlert(null);
  };

  const updateChildrenOnChildrenAvailableClick = (e) => {
    const { value } = e.target;
    if (value === "no") {
      dispatch(
        formActionCreators.postFormData({
          userInformation: {
            childrenDetails: {
              ...childrenDetails,
              children: [],
            },
          },
          benificiaries: {
            ...benificiaries,
            benificiariesSplit: [],
          },
        })
      );
    }
    triggerAutoSave();
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setValue("phone", formattedPhone);
  };

  return (
    <div className="address-details-wrapper">
      <FormCard>
        <span className="welcome-user">{`Welcome ${getOnlyFirstNameFromName(
          profile.name
        )}`}</span>
        <span className="welcome-user-desc">
          Please share some basic information about you and your family. Nothing
          challenging.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onUserInformationSubmit)}>
        <FormCard>
          <span className="form-title">Address</span>
          <div className="form-controls">
            <FormInput
              label="Address"
              name="address"
              error={errors.address?.message}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
            <div className="form-controls-section">
              <FormInput
                label="City"
                name="city"
                error={errors.city?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
              <FormInput
                label="County"
                name="county"
                error={errors.county?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
            </div>
            <div className="form-controls-section">
              <FormInput
                label="State"
                name="state"
                error={errors.state?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
              <FormInput
                label="Zip Code"
                name="zipCode"
                error={errors.zipCode?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
            </div>
            <div className="form-controls-section">
              <FormInput
                label="Phone"
                name="phone"
                error={errors.phone?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
                onChange={handlePhoneChange}
              />
              <FormInput
                label="DOB"
                name="dob"
                type="date"
                error={errors.dob?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
            </div>
            <FormRadio
              name="gender"
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ]}
              inputRef={register}
              label="Gender"
              onBlur={triggerAutoSave}
            />
            <FormRadio
              label="Are you US Citizen?"
              name="isUsCitizen"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
            <FormRadio
              label="Status"
              name="maritalStatus"
              options={[
                { value: "single", label: "Single" },
                { value: "married", label: "Married/ Domestic Partner" },
              ]}
              inputRef={register}
              onChange={verifyAndUpdateMaritalStatus}
            />
            <div className="form-controls-text-input">
              <span>Number of properties</span>
              <span>
                <FormInput
                  name="properties"
                  error={errors.properties?.message}
                  inputRef={register}
                  onBlur={verifyAndUpdateProperties}
                  defaultValue=""
                />
              </span>
            </div>
            <FormRadio
              label="Do you have children?"
              name="isChildrenAvailable"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onChange={updateChildrenOnChildrenAvailableClick}
            />
            {watchIsChildrenAvailable === "yes" && (
              <div className="form-controls-text-input">
                <span>Number of children</span>
                <span>
                  <FormInput
                    type="number"
                    name="noOfChildren"
                    error={errors.noOfChildren?.message}
                    inputRef={register}
                    onBlur={verifyAndUpdateChildren}
                  />
                </span>
              </div>
            )}
          </div>
        </FormCard>
        <div className="form-details-buttons">
          <NextButton type="submit" disabled={userInfoState.isPositingData}>
            Submit
          </NextButton>
        </div>
      </form>
      <Alert
        show={showChildrenAlert}
        onYesClick={onChildrenChangeYesClick}
        onNoClick={onChildrenChangeNoClick}
      />
      <Alert
        show={showPropertiesAlert}
        onYesClick={onPropertiesChangeYesClick}
        onNoClick={onPropertiesChangeNoClick}
      />
    </div>
  );
};
