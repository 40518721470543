import React from "react";

import TextInput from "../TextInput";
import TextArea from "../TextArea";
import FormError from "../FormError";
import "./FormInput.scss";

export default ({
  label,
  type,
  onChange,
  step,
  value,
  error,
  name,
  inputRef,
  className,
  onBlur,
  defaultValue,
  placeholder,
  disabled,
  rows,
  readOnly,
}) => (
  <div className={`forminput-wrapper ${className}`}>
    <span className="forminput-label">
      {label || ""} {error && <FormError error={error} />}
    </span>
    {type === "textarea" ? (
      <TextArea
        type={type}
        rows={rows}
        onChange={onChange}
        value={value}
        error={error}
        name={name}
        inputRef={inputRef}
        onBlur={onBlur}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    ) : (
      <TextInput
        type={type}
        onChange={onChange}
        value={value}
        error={error}
        name={name}
        step={step}
        inputRef={inputRef}
        onBlur={onBlur}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
      />
    )}
  </div>
);
