/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { formActionCreators } from "../../../actions/form.actions";
import "./LeftNavigation.scss";

export default () => {
  const dispatch = useDispatch();

  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;
  const { activeItem, completedItems } = navItems || {};

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { userInfoConfirmed } = userInfo || {};

  const navigationState = useSelector((state) => state.navigationState);
  const activeLeftNavItemIndex =
    (navigationState || []).findIndex((item) => item.id === activeItem) + 1;
  const activeLeftNavItemId = activeItem;

  const getNavItemTop = (navItemId) => navItemId * 25 + 65;

  const getNavItemItemTop = (navItemId) => {
    if (navItemId === activeLeftNavItemIndex) {
      return navItemId * 25 + 65;
    }
    if (navItemId === activeLeftNavItemIndex - 1) {
      return navItemId * 25 + 65 - 10;
    }
    if (navItemId === activeLeftNavItemIndex + 1) {
      return navItemId * 25 + 65 + 10;
    }
    if (navItemId === activeLeftNavItemIndex - 2) {
      return navItemId * 25 + 65 - 10;
    }
    if (navItemId === activeLeftNavItemIndex + 2) {
      return navItemId * 25 + 65 + 10;
    }
    if (navItemId < activeLeftNavItemIndex - 2) {
      return navItemId * 25 + 65 - 10;
    }
    return navItemId * 25 + 65 + 10;
  };

  const getNavigationTextClassName = (navItemId) => {
    if (navItemId === activeLeftNavItemIndex) {
      return "left-navigation-item-text";
    }
    if (
      navItemId === activeLeftNavItemIndex + 1 ||
      navItemId === activeLeftNavItemIndex - 1
    ) {
      return "left-navigation-item-text left-navigation-adjacentitem-text";
    }
    // if (
    //   navItemId === activeLeftNavItemIndex + 2 ||
    //   navItemId === activeLeftNavItemIndex - 2
    // ) {
    //   return "left-navigation-item-text left-navigation-nexttitem-text";
    // }
    return "";
  };

  const getNavItemLeft = (navItemId) => {
    if (navItemId === activeLeftNavItemIndex) {
      return 49.2;
    }
    if (navItemId === activeLeftNavItemIndex + 1) {
      return 36;
    }
    if (navItemId === activeLeftNavItemIndex + 2) {
      return 0;
    }
    if (navItemId === activeLeftNavItemIndex - 1) {
      return 32;
    }
    if (navItemId === activeLeftNavItemIndex - 2) {
      return 0;
    }
    return 0;
  };

  // const postNavItems = (navItem) => {
  //   if ((completedItems || []).includes(navItem.id) && !userInfoConfirmed) {
  //     dispatch(
  //       formActionCreators.postNavItems({ ...navItems, activeItem: navItem.id })
  //     );
  //   }
  // };

  // uncomment the above code if user should not go back after userInfo confirmed
  const postNavItems = (navItem) => {
    if (
      ((completedItems || []).includes(navItem.id) || navItem.id === 11) &&
      !userInfoConfirmed
    ) {
      dispatch(
        formActionCreators.postNavItems({ ...navItems, activeItem: navItem.id })
      );
    }
  };

  const getItemClassName = (navItemIndex) => {
    if (navItemIndex === activeLeftNavItemIndex) {
      return "left-navigation-item-active";
    }
    if (
      navItemIndex === activeLeftNavItemIndex - 1 ||
      navItemIndex === activeLeftNavItemIndex + 1
    ) {
      return "left-navigation-item-adjacent";
    }
    if (
      navItemIndex === activeLeftNavItemIndex - 2 ||
      navItemIndex === activeLeftNavItemIndex + 2
    ) {
      return "left-navigation-item-nextadjacent";
    }

    return null;
  };

  const getItemCompletedClassName = (leftNavItemId) =>
    (completedItems || []).includes(leftNavItemId)
      ? "left-navigation-item-completed"
      : "";

  const getNavItemCircle = (navItemId) => {
    if (navItemId === activeLeftNavItemId)
      return <span style={{ backgroundColor: "#FA6401" }} />;
    if ((completedItems || []).includes(navItemId)) {
      return <span className="navitem-completed-icon" />;
    }
    return <span style={{ backgroundColor: "black" }} />;
  };

  return (
    <div className="left-navigation">
      {activeLeftNavItemIndex === 1 ? (
        <div className="left-navigation-text">
          Key Points
          <li>
            Do any of your children have special needs? Or are on any government
            assistance?
          </li>
          <li>Do you have more than $2,000,000 in life insurance?</li>
          <li>
            Are you interested in asset protection features for real estate?
          </li>
          <li>Are you self-employed and own a corporation?</li>
          <li>Do you own assets outside the county?</li>
          <li>
            Have you thought about how to pass on your digital assets?
            Passwords?
          </li>
          <li>Having trouble deciding on who would be a Successor Trustee?</li>
          <li>
            <b>Remember:</b> Use the Question Note Pad and the Drag and Drop
            Menu (over there -&gt;) to help you through this process. By writing
            down your questions, your attorney will make sure to discuss them at
            your meeting.
          </li>
        </div>
      ) : (
        <>
          <div
            className="left-navigation-item-semicircle"
            style={{
              top: `${getNavItemTop(activeLeftNavItemIndex) - 30}px`,
              left: "4px",
            }}
          />
          <div
            className="left-navigation-item-circle-border"
            style={{
              top: `${getNavItemTop(activeLeftNavItemIndex) - 40}px`,
              left: "4px",
            }}
          />
          <div
            className="left-navigation-left-border-top"
            style={{
              height: `${getNavItemTop(activeLeftNavItemIndex) - 40}px`,
              left: "4px",
            }}
          />
          <div
            className="left-navigation-left-border-bottom"
            style={{
              top: `${getNavItemTop(activeLeftNavItemIndex) + 40}px`,
              height: `calc(100% - ${
                getNavItemTop(activeLeftNavItemIndex) + 40
              }px)`,
              left: "4px",
            }}
          />
          {(navigationState || []).map((navItem, index) => (
            <div
              className={`left-navigation-item ${getItemCompletedClassName(
                navItem.id
              )} ${getItemClassName(index + 1)} `}
              style={{
                top: `${getNavItemItemTop(index + 1)}px`,
                left: `${getNavItemLeft(index + 1)}px`,
              }}
              key={index}
            >
              {getNavItemCircle(navItem.id)}
              <span
                className={getNavigationTextClassName(index + 1)}
                onClick={() => postNavItems(navItem)}
                onKeyPress={() => postNavItems(navItem)}
                role="button"
              >
                {navItem.displayText}
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
