export const checkIfNamePresentInSavedNames = (savedNames, newName) =>
  (savedNames || []).find(
    (savedName) =>
      savedName.firstName === newName.firstName &&
      savedName.lastName === newName.lastName
  );

export const getUpdatedSavedNames = (savedNames, newSavedName) => {
  if (checkIfNamePresentInSavedNames(savedNames, newSavedName)) {
    return [
      ...savedNames.filter(
        (savedName) =>
          !(
            savedName.firstName === newSavedName.firstName &&
            savedName.lastName === newSavedName.lastName
          )
      ),
      newSavedName,
    ];
  }
  return [...savedNames, newSavedName];
};

export const getOnlyFirstNameFromName = (name) =>
  (name || "").split(" ").slice(0, -1).join(" ");

export const getFirstAndLastNameFromFullName = (name) => ({
  firstName: (name || "").split(" ").slice(0, -1).join(" "),
  lastName: (name || "").split(" ").slice(-1).join(" "),
});

export const getFullNameFromFirstAndLast = (firstName, lastName) =>
  `${firstName || ""} ${lastName || ""}`.trim();

export const getFullChildren = (userInformation) => {
  const {
    childrenDetails,
    priorMarriageChildrenDetails,
    deceasedChildrenDetails,
  } = userInformation || {};

  const fullChildren = [
    ...(childrenDetails?.children || []),
    ...(priorMarriageChildrenDetails?.children || []),
    ...(deceasedChildrenDetails?.children || []),
  ];
  return fullChildren;
};

export const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getMinorChildren = (fullChildren) =>
  (fullChildren || []).filter((child) => {
    if (child.dob) {
      const age = calculateAge(new Date(child.dob));
      if (age < 18) {
        return true;
      }
      return false;
    }
    return false;
  });

export const formatPhoneNumber = (phoneNumberString) => {
  let clearedString = phoneNumberString.replace(/[^\d]/g, "");
  clearedString = clearedString.replaceAll("(", "");
  clearedString = clearedString.replaceAll(")", "");
  clearedString = clearedString.replaceAll("-", "");
  clearedString = clearedString.replaceAll(" ", "");
  clearedString = clearedString.substring(
    0,
    Math.min(clearedString.length, 10)
  );
  let formatted = "";
  if (clearedString.length > 0 && clearedString.length <= 3) {
    formatted = `(${clearedString}`;
  } else if (clearedString.length > 3 && clearedString.length <= 6) {
    formatted = `(${clearedString.substring(0, 3)}) ${clearedString.substring(
      3,
      6
    )}`;
  } else if (clearedString.length > 6 && clearedString.length <= 10) {
    formatted = `(${clearedString.substring(0, 3)}) ${clearedString.substring(
      3,
      6
    )}-${clearedString.substring(6, 10)}`;
  }
  return formatted;
};

export const getDateInUSFormat = (date) => {
  if (date) {
    const dateObject = new Date(date);
    return `${
      dateObject.getMonth() + 1
    }-${dateObject.getDate()}-${dateObject.getFullYear()}`;
  }
  return "";
};
