/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import closeIcon from "../../assets/images/closeIcon.svg";

import "./FormCard.scss";

export default ({ children, showCloseIcon, onFormCardCloseClick }) => (
  <div className="form-card">
    {showCloseIcon && (
      <img
        className="form-card-close-icon"
        src={closeIcon}
        alt="x"
        onClick={onFormCardCloseClick}
      />
    )}
    {children}
  </div>
);
