import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const { REACT_APP_GA_TRACKING_CODE } = process.env;

const setUserIDForTracker = ({ userId }) => {
  ReactGA.set({
    userId,
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  });
};

const tracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const userId = localStorage.getItem("sub");

  useEffect(() => {
    // if (!window.location.href.includes("localhost")) {
    console.log("React GA is initialised with", REACT_APP_GA_TRACKING_CODE);
    ReactGA.initialize(REACT_APP_GA_TRACKING_CODE);
    // }
    if (userId) {
      setUserIDForTracker({ userId });
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default tracker;

export { setUserIDForTracker };
