/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import FormCard from "../../../components/FormCard";
import FormInput from "../../../components/FormInput";
import FormSelect from "../../../components/FormSelect";
import FileUpload from "../../../components/FileUpload";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import clearIcon from "../../../assets/images/clear.svg";
import API from "../../../services/api";
import { formActionCreators } from "../../../actions/form.actions";
import "./Assets.scss";

const propertyDetailsSchema = {
  fileName: yup.string(),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup.string(),
};
const retirementAccountsSchema = {
  institutionName: yup.string(),
  type: yup.string(),
  accountNumber: yup.string(),
  amount: yup.string(),
};
const assetsSchema = yup.object().shape({
  propertyDetails: yup.array().of(yup.object().shape(propertyDetailsSchema)),
  retirementAccounts: yup
    .array()
    .of(yup.object().shape(retirementAccountsSchema)),
  otherAssets: yup.string(),
});
export default ({ goToNextNavItem }) => {
  const dispatch = useDispatch();
  const [fileUploadStatus, setFileUploadStatus] = useState({});

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { assetsDetails } = userInfo || {};
  const { propertyDetails, retirementAccounts, ...restAssetsDetails } =
    assetsDetails || {};

  const defaultPropertDetails = [
    {
      fileName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
    },
  ];
  const defaultRetirementAccounts = [
    {
      institutionName: "",
      type: "",
      accountNumber: "",
      amount: "",
    },
  ];
  const {
    register,
    control,
    errors,
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(assetsSchema),
    defaultValues: {
      ...restAssetsDetails,
      propertyDetails:
        propertyDetails.length > 0 ? propertyDetails : defaultPropertDetails,
      retirementAccounts:
        retirementAccounts.length > 0
          ? retirementAccounts
          : defaultRetirementAccounts,
    },
  });

  const { fields: propertyFields, append: propertyAppend } = useFieldArray({
    control,
    name: "propertyDetails",
  });

  const {
    fields: retirementFields,
    append: retirementFieldsAppend,
    remove: retirementFieldsRemove,
  } = useFieldArray({
    control,
    name: "retirementAccounts",
  });

  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onAssetsSubmit = () => {
    console.log("frm submitted", getValues());
    dispatch(
      formActionCreators.postFormData({
        assetsDetails: getValues(),
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        assetsDetails: data,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const handleInputPropertyFilesChanged = (e, propertyIndex) => {
    const { files } = e.target;
    const file = files[0];
    const fileName = file.name;
    setFileUploadStatus({
      ...fileUploadStatus,
      [`property${propertyIndex}`]: {
        isLoading: true,
        success: false,
        errorMessage: "",
      },
    });
    const data = new FormData();
    data.append("file", file);
    API.post("/form/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(() => {
        setFileUploadStatus({
          ...fileUploadStatus,
          [`property${propertyIndex}`]: {
            isLoading: false,
            success: true,
            errorMessage: "",
          },
        });
        const updatedPropertyDetails = getValues().propertyDetails.map(
          (propertyDetail, index) => {
            if (index === propertyIndex) {
              return { ...propertyDetail, fileName };
            }
            return propertyDetail;
          }
        );
        setValue("propertyDetails", updatedPropertyDetails);
        triggerAutoSave();
      })
      .catch((error) => {
        setFileUploadStatus({
          ...fileUploadStatus,
          [`property${propertyIndex}`]: {
            isLoading: false,
            success: false,
            errorMessage: error.errorMessage,
          },
        });
      });
  };
  // const formatter = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  // const getValueInCurrencyFormat = (number) => formatter.format(number);

  // const deletePropertFile = (propertyIndex) => {
  //   const data = getValues();
  //   const properties = data.propertyDetails;
  //   const property = properties[propertyIndex];
  //   const { fileName } = property;
  //   API.delete(`/form/file/${fileName}`);
  // };

  return (
    <div className="assets-wrapper">
      <form onSubmit={handleSubmit(onAssetsSubmit)}>
        <FormCard>
          <span className="form-header-main-title">Assets</span>
          <span className="form-title-desc">
            It is important your Trust always has stuff, like real property or
            money, in it to be valid. Your retirement accounts and accounts with
            separate beneficiary statements are normally not put into the Trust.
            The “Other Assets” box at the bottom is a good place to just list
            the significant assets you own. If you have any questions about the
            assets that should be in the Trust make sure you ask your lawyer.
          </span>
        </FormCard>
        {propertyFields.map((item, index) => (
          <FormCard key={item.id}>
            <span className="form-header-title">{`Property Details - ${
              index + 1
            }`}</span>
            <div key={item.id} className="form-controls">
              <FormInput
                label="Adress"
                name={`propertyDetails[${index}].address`}
                error={errors?.propertyDetails?.[index]?.address?.message || ""}
                inputRef={register()}
                defaultValue={item.address}
                onBlur={triggerAutoSave}
              />
              <FormInput
                label="City"
                name={`propertyDetails[${index}].city`}
                error={errors?.propertyDetails?.[index]?.city?.message || ""}
                inputRef={register()}
                defaultValue={item.city}
                onBlur={triggerAutoSave}
              />
              <div className="form-controls-children">
                <FormInput
                  label="State"
                  name={`propertyDetails[${index}].state`}
                  error={errors?.propertyDetails?.[index]?.state?.message || ""}
                  inputRef={register()}
                  defaultValue={item.state}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="Zip Code"
                  name={`propertyDetails[${index}].zipCode`}
                  error={
                    errors?.propertyDetails?.[index]?.zipCode?.message || ""
                  }
                  inputRef={register()}
                  defaultValue={item.zipCode}
                  onBlur={triggerAutoSave}
                />
              </div>
              {/* <span className="form-input-footer">
                You would like to add more property?{" "}
                <span
                  onClick={() => propertyAppend({})}
                  onKeyPress={() => propertyAppend({})}
                  role="button"
                  tabIndex={0}
                >
                  Click here
                </span>
              </span> */}
              <div className="form-input-upload">
                <div className="file-upload-button">
                  <FileUpload
                    handleInputFilesChanged={(e) =>
                      handleInputPropertyFilesChanged(e, index)
                    }
                    isLoading={fileUploadStatus[`property${index}`]?.isLoading}
                  />
                </div>
                <div className="file-upload-text">
                  <FormInput
                    label={`Property fileName -${index + 1}`}
                    name={`propertyDetails[${index}].fileName`}
                    error={
                      errors?.propertyDetails?.[index]?.fileName?.message || ""
                    }
                    inputRef={register()}
                    readOnly
                    // onBlur={triggerAutoSave}
                    defaultValue={item.fileName}
                  />
                </div>
              </div>
              {/* <div className="file-clear">
                  <img
                    src={clearIcon}
                    alt="x"
                    onClick={() => deletePropertFile(index)}
                  />
                </div> */}
            </div>
          </FormCard>
        ))}
        {retirementFields.map((item, index) => (
          <FormCard
            key={`${index + 1}`}
            showCloseIcon={index !== 0}
            onFormCardCloseClick={() => retirementFieldsRemove(index)}
          >
            <span className="form-header-title">
              Retirement Accounts, Financial or Bank Accounts
            </span>
            <div className="form-controls">
              <FormInput
                label="Institution Name"
                name={`retirementAccounts[${index}].institutionName`}
                error={
                  errors.retirementAccounts?.[index].institutionName?.message ||
                  ""
                }
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.institutionName}
              />
              <FormSelect
                label="Type"
                inputRef={register}
                name={`retirementAccounts[${index}].type`}
                error={errors?.retirementAccounts?.[index].type?.message || ""}
                onChange={triggerAutoSave}
                defaultValue={item.type}
                options={[
                  { value: "Bank", displayText: "Bank" },
                  { value: "Stock", displayText: "Stock" },
                  {
                    value: "Retirement Account",
                    displayText: "Retirement Account",
                  },
                ]}
              />
              <FormInput
                label="Last-4 of the Account Number"
                name={`retirementAccounts[${index}].accountNumber`}
                error={
                  errors.retirementAccounts?.[index].accountNumber?.message ||
                  ""
                }
                defaultValue={item.accountNumber}
                inputRef={register()}
                onBlur={triggerAutoSave}
              />
              <div className="amount-input">
                <span className="forminput-label">Amount</span>
                <Controller
                  as={
                    <NumberFormat
                      thousandSeparator
                      prefix="$ "
                      customInput={FormInput}
                    />
                  }
                  name={`retirementAccounts[${index}].amount`}
                  error={
                    errors?.retirementAccounts?.[index].amount?.message || ""
                  }
                  variant="outlined"
                  defaultValue={item.amount}
                  onBlur={triggerAutoSave}
                  control={control}
                />
              </div>
            </div>
            <span className="form-input-footer">
              You would like to add more Retirement Accounts, Financial or Bank
              Accounts ?{" "}
              <span
                onClick={() => retirementFieldsAppend({})}
                onKeyPress={() => retirementFieldsAppend({})}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </FormCard>
        ))}
        <FormCard>
          <span className="form-header-title">Other Assets</span>
          <div className="form-controls">
            <FormInput
              type="textarea"
              rows={6}
              name="otherAssets"
              error={errors?.otherAssets?.message || ""}
              inputRef={register()}
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
