import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import formReducers from "./reducers/form.reducers";
import initFormState from "./reducers/initFormState";
import formSagas from "./sagas/form.sagas";
import profileReducers from "./reducers/profile.reducers";
import initProfileState from "./reducers/initProfileState";

const initialState = {
  ...initFormState,
  ...initProfileState,
};

const reducers = {
  ...formReducers,
  ...profileReducers,
};

function* rootSaga() {
  yield all([...formSagas]);
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers(reducers),
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
