/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from "react";

import Header from "../../components/Header";
import Divider from "../../components/Divider";
import Footer from "../../components/Footer";
import HeroCarousal from "./HeroCarousal";
import VideoSection from "./VideoSection";
import PricingSection from "./PricingSection";
import TestimonialCarousal from "./TestimonialCarousal";
import GetInTouch from "./GetInTouch";
import debounce from "../../debounce";
import "./Home.scss";
import { authActions } from "../../App";
import startSessionTimer from "../../helpers/sessionTimer";
import AppConstants from "../../constants/App.constants";

export default () => {
  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const navItems = [
    {
      id: "home",
      name: "Home",
      toLink: "",
      reference: homeRef,
    },
    {
      id: "about",
      name: "About",
      toLink: "#about",
      reference: aboutRef,
    },
    {
      id: "services",
      name: "Services",
      toLink: "#services",
      reference: servicesRef,
    },
    {
      id: "contact",
      name: "Contact",
      toLink: "#contact",
      reference: contactRef,
    },
  ];

  // extra navitems is to highlight contact when testimonial section is arrived
  const extraNavItems = [
    {
      id: "home",
      name: "Home",
      toLink: "",
      reference: homeRef,
    },
    {
      id: "about",
      name: "About",
      toLink: "#about",
      reference: aboutRef,
    },
    {
      id: "services",
      name: "Services",
      toLink: "#services",
      reference: servicesRef,
    },
    {
      id: "testimonial",
      name: "Contact",
      toLink: "#contact",
      reference: contactRef,
    },
  ];

  const signout = () => {
    authActions.signOut();
  };

  useEffect(() => {
    if (authActions.isAuthenticated()) {
      console.log("Session timer started", AppConstants.inactivityTimeoutSecs);
      startSessionTimer(AppConstants.inactivityTimeoutSecs, signout);
    }

    document.querySelectorAll(".nav-item")[0].classList.add("nav-item-active");

    const handleScroll = debounce(() => {
      let activeElemnt;
      const navItemElements = document.querySelectorAll(".nav-item");
      extraNavItems.forEach((navItem, index) => {
        const element = document.querySelector(`#${navItem.id}`);
        const elementPositionTop = element?.offsetTop;
        const currentScrollPosition = window.scrollY + 48;
        if (currentScrollPosition > elementPositionTop) {
          activeElemnt = element;
          navItemElements.forEach((ele) => {
            ele.classList.remove("nav-item-active");
          });
          navItemElements[index].classList.add("nav-item-active");
        }
      });
    }, 50);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home-page">
      <div className="header-with-divider">
        <div className="header-wrapper">
          <Header navItems={navItems} />
        </div>
        <Divider />
      </div>
      <div className="home-page-body-wrapper">
        <div className="home-page-body">
          <section ref={homeRef} id="home">
            <HeroCarousal />
          </section>
          <section ref={aboutRef} id="about" className="video-section-wrapper">
            <VideoSection />
          </section>
          <section
            ref={servicesRef}
            id="services"
            className="pricing-section-wrapper"
          >
            <PricingSection />
          </section>
          {/* <section id="testimonial" className="testimonila-carousal-wrapper">
            <TestimonialCarousal />
          </section> */}
        </div>
      </div>
      <section ref={contactRef} id="contact" className="get-in-touch-wrapper">
        <GetInTouch />
      </section>
      <div className="footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};
