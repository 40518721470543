/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import "./CarousalIndicators.scss";

export default ({ slides, activeSlideIndex, setActiveSlideIndex }) => (
  <div className="carousal-indicators">
    {slides.map((slide, index) => (
      <span
        key={index}
        className={`carousal-indicator ${
          index === activeSlideIndex ? "active" : ""
        }`}
        onClick={() => setActiveSlideIndex(index)}
      />
    ))}
  </div>
);
