import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import NON_SECURE_API from "../../services/non-secure-api";
import AuthWrapper from "../../components/AuthWrapper";
import Button from "../../components/Button";
import VerifiedIcon from "../../assets/images/verified.svg";
import "./VerifyEmail.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const [verifyEmailState, setVerifyEmailState] = useState({
    isLoading: true,
    errorMessage: "",
    success: "",
  });

  const history = useHistory();
  const query = useQuery();
  const token = query.get("token");

  const verifyEmail = () => {
    NON_SECURE_API.post("/auth/verifyemail", { token })
      .then(() => {
        setVerifyEmailState({
          ...verifyEmailState,
          isLoading: false,
          success: "success",
        });
      })
      .catch((error) => {
        setVerifyEmailState({
          ...verifyEmailState,
          isLoading: false,
          errorMessage: error.response.data.errorMessage,
        });
      });
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  const getVerificationStatusText = () => {
    if (verifyEmailState.isLoading) {
      return <span>Verifying Email...</span>;
    }
    if (verifyEmailState.success) {
      return (
        <span>
          Account Verified
          <img src={VerifiedIcon} alt="" />
        </span>
      );
    }
    if (verifyEmailState.errorMessage) {
      return <span>Could Not Verify</span>;
    }
    return "";
  };

  const onSignInClick = () => {
    history.push("/signin");
  };

  return (
    <AuthWrapper page="signup">
      <div className="verify-email-status">{getVerificationStatusText()}</div>
      {!verifyEmailState.isLoading && (
        <div className="verify-email-form">
          {verifyEmailState.success && (
            <>
              <span>Thank you, your email has been verified. </span>
              <span>Please use the link below to sign in to your account.</span>
              <Button
                className="verify-email-signin-button"
                onClick={onSignInClick}
              >
                Sign In
              </Button>
            </>
          )}
          {verifyEmailState.errorMessage && (
            <span>
              <b>Error :</b> {verifyEmailState.errorMessage}
            </span>
          )}
          <div className="verify-email-contact">
            Need help? <a href="/#contact">Contact us</a>
          </div>
        </div>
      )}
    </AuthWrapper>
  );
};
