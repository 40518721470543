/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CheckBox from "../../../components/CheckBox";
import attornerPowers from "../../../constants/attorneyPowers";
import NewSavedName from "../../../components/NewSavedName";
import FormCard from "../../../components/FormCard";
import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import { formActionCreators } from "../../../actions/form.actions";
import { authActions } from "../../../App";

import "./DurablePowerOfAttorney.scss";
import {
  getOnlyFirstNameFromName,
  getFullNameFromFirstAndLast,
} from "../../../helpers";

const powerOfAttorneySchema = yup.object().shape({
  firstPowerOfAttorney: yup.string(),
  secondPowerOfAttorney: yup.string(),
});

export default ({ goToNextNavItem }) => {
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { durablePowerOfAttorney, userInformation } = userInfo || {};
  const { spouseDetails } = userInformation || {};

  const {
    firstPowerOfAttorney,
    secondPowerOfAttorney,
    isAttorneyPowersUpdated,
    attorneyPowers: initialAttorneyPowers,
    ...restDurablePowerOfAttorney
  } = durablePowerOfAttorney || {};

  const [attorneyPowers, setAttorneyPowers] = useState(
    isAttorneyPowersUpdated === "yes"
      ? initialAttorneyPowers
      : attornerPowers.map((power) => power.name)
  );

  const addAllPowers = () => {
    const powersWithNames = attornerPowers.map((power) => power.name);
    setAttorneyPowers([...powersWithNames]);
  };

  const {
    register,
    setValue,
    errors,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(powerOfAttorneySchema),
    defaultValues: {
      ...restDurablePowerOfAttorney,
      firstPowerOfAttorney:
        firstPowerOfAttorney ||
        getFullNameFromFirstAndLast(
          spouseDetails.firstName,
          spouseDetails.lastName
        ),
      secondPowerOfAttorney: secondPowerOfAttorney || "",
    },
  });

  const addAttorneyPowers = (attornerPowerName) => {
    if (attorneyPowers.indexOf(attornerPowerName) !== -1) {
      setAttorneyPowers([
        ...attorneyPowers.filter((power) => power !== attornerPowerName),
      ]);
    } else {
      setAttorneyPowers([...attorneyPowers, attornerPowerName]);
    }
  };

  const [checkAllChecked, setCheckAllChecked] = useState(
    attornerPowers.length === attorneyPowers.length
  );

  const onCheckAllClick = () => {
    if (checkAllChecked) {
      setAttorneyPowers([]);
      setCheckAllChecked(false);
    } else {
      addAllPowers();
      setCheckAllChecked(true);
    }
  };

  const onSavedNameSubmit = ({ name, data }) => {
    if (name === "firstPowerOfAttorney") {
      setValue("firstPowerOfAttorney", `${data.firstName} ${data.lastName}`);
    }
    if (name === "secondPowerOfAttorney") {
      setValue("secondPowerOfAttorney", `${data.firstName} ${data.lastName}`);
    }
  };

  const { name: userName } = authActions.getUserInfo();

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    const fullSavedName = `${savedName.firstName} ${savedName.lastName}`.trim();
    if (fullSavedName === userName) {
      setError(`${itemIndex}`, {
        type: "manual",
        message: "Durable Power of attorney cannot be yourself",
      });
    } else {
      setValue(`${itemIndex}`, fullSavedName);
      clearErrors(`${itemIndex}`);
    }

    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  const dispatch = useDispatch();
  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onPowerOfAttorneySubmit = (data) => {
    const newData = {
      ...data,
      attorneyPowers,
      isAttorneyPowersUpdated: "yes",
    };
    dispatch(
      formActionCreators.postFormData({
        durablePowerOfAttorney: newData,
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    const newData = {
      ...data,
      attorneyPowers,
    };
    dispatch(
      formActionCreators.postFormData({
        durablePowerOfAttorney: newData,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const profile = useSelector((state) => state.profile);

  return (
    <div className="durable-power-attorney-wrapper">
      <FormCard>
        <span className="form-header-main-title">{`${getOnlyFirstNameFromName(
          profile.name
        )}'s Durable Power of attorney`}</span>
        <span className="form-title-desc">
          Again, you are picking a person you trust to take care of your money.
          The Durable Power of Attorney allows you to have an Agent help with
          your finances if you become sick or cannot take care of your financial
          life. It is only good during your lifetime. Married couples usually
          pick their spouse for the First Agent and then select a Second Agent
          as well. Most people choose all the powers listed below to give the
          Agent, but you can also be selective.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onPowerOfAttorneySubmit)}>
        <FormCard>
          <span className="form-header-title">First Agent</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "firstPowerOfAttorney"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "firstPowerOfAttorney")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "firstPowerOfAttorney")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="First power of attorney"
                name="firstPowerOfAttorney"
                error={errors.firstPowerOfAttorney?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("firstPowerOfAttorney")}
                onKeyPress={() => setOpenNewSavedName("firstPowerOfAttorney")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "firstPowerOfAttorney" && (
          <NewSavedName
            title="New Attorney"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">Second Agent</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "secondPowerOfAttorney"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "secondPowerOfAttorney")}
              onDragOver={(e) =>
                onDragOverSavedNames(e, "secondPowerOfAttorney")
              }
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Second power of attorney"
                name="secondPowerOfAttorney"
                error={errors.secondPowerOfAttorney?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("secondPowerOfAttorney")}
                onKeyPress={() => setOpenNewSavedName("secondPowerOfAttorney")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "secondPowerOfAttorney" && (
          <NewSavedName
            title="New Attorney"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">
            Powers to give to your Agent
          </span>
          <span className="form-title-desc">
            To grant each of the following powers, check the box in front or
          </span>
          <div
            className="check-all-checkbox"
            onClick={onCheckAllClick}
            onKeyPress={onCheckAllClick}
            role="button"
            tabIndex={0}
          >
            <CheckBox
              checked={attornerPowers.length === attorneyPowers.length}
            />
            <div className="check-all-checkbox-text">check All</div>
          </div>
          <div className="attorney-powers">
            {attornerPowers.map((power) => (
              <div
                key={power.id}
                className="attorney-power"
                onClick={() => addAttorneyPowers(power.name)}
                onKeyPress={() => addAttorneyPowers(power.name)}
                tabIndex={0}
                role="button"
              >
                <div>
                  <CheckBox
                    checked={(attorneyPowers || []).includes(power.name)}
                  />
                </div>
                <div className="power-name">
                  <div>{power.name}</div>
                </div>
              </div>
            ))}
          </div>
        </FormCard>
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
