/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import leftNavItems from "../../leftNavItems";
import Divider from "../../components/Divider";
import LeftNavigation from "./LeftNavigation";
import FormCard from "../../components/FormCard";
import FormHeader from "./FormHeader";
import { formActionCreators } from "../../actions/form.actions";
import chartIconActive from "../../assets/images/chartIconActive.svg";
import SaveIcon from "../../assets/images/saveActive.svg";
import DragIcon from "../../assets/images/DragMove.svg";
import questionSubmitIcon from "../../assets/images/questionSubmit.svg";
import trashIcon from "../../assets/images/trashIcon.svg";
import { authActions } from "../../App";
import { getFullChildren, getMinorChildren } from "../../helpers";
import startSessionTimer from "../../helpers/sessionTimer";
import AppConstants from "../../constants/App.constants";

import "./Form.scss";

export default () => {
  const dispatch = useDispatch();
  const [newQuestion, setNewQuestion] = useState("");
  const [showSavedNames, setShowSavedNames] = useState(true);
  const [showQuestions, setShowQuestions] = useState(true);

  const userInfoState = useSelector((state) => state.userInfoState);
  const navItemsState = useSelector((state) => state.navItemsState);
  const savedNamesState = useSelector((state) => state.savedNamesState);
  const questionsState = useSelector((state) => state.questionsState);
  const navigationState = useSelector((state) => state.navigationState);

  const { savedNames } = savedNamesState;
  const { questions } = questionsState;
  const { navItems } = navItemsState;
  const { userInfo } = userInfoState;
  const { userInformation } = userInfo || {};
  const { addressDetails, spouseDetails } = userInformation || {};

  const signout = () => {
    authActions.signOut();
  };

  useEffect(() => {
    dispatch(formActionCreators.fetchFormData());
    dispatch(formActionCreators.getNavItems());
    dispatch(formActionCreators.getSavedNames());
    dispatch(formActionCreators.getQuestions());

    startSessionTimer(AppConstants.inactivityTimeoutSecs, signout);
    console.log(
      "session timer started with",
      AppConstants.inactivityTimeoutSecs
    );
  }, []);

  const getOnlyFirstNameFromName = (name) => name.split(" ")[0];

  const fullChildren = getFullChildren(userInformation);
  const minorChildren = getMinorChildren(fullChildren);
  const minorChildrenCount = minorChildren.length;

  let minorNamesConcatinated = "";

  const getConcatIdentifier = (minorIndex) => {
    if (minorIndex === 0) {
      return "";
    }
    if (minorChildren.length === minorIndex + 1) {
      return " and";
    }
    return ",";
  };

  (minorChildren || []).forEach((minor, index) => {
    minorNamesConcatinated += `${getConcatIdentifier(index)} ${
      minor.firstName
    }`;
  });

  useEffect(() => {
    const getUpdatedNavItemsWithName = (leftnavItems) => {
      const { name } = authActions.getUserInfo();
      const spouseName =
        `${spouseDetails?.firstName || ""} ${
          spouseDetails?.lastName || ""
        }`.trim() || "Spouse";
      return (leftnavItems || []).map((navItem) => {
        if (navItem.id === 1) {
          return {
            ...navItem,
            displayText: `${getOnlyFirstNameFromName(name)}'s Information`,
          };
        }
        if (navItem.id === 5) {
          return {
            ...navItem,
            displayText: `Guardian of ${minorNamesConcatinated}`,
          };
        }
        if (navItem.id === 6) {
          return {
            ...navItem,
            displayText: `${getOnlyFirstNameFromName(name)}'s Durable POA`,
          };
        }
        if (navItem.id === 7) {
          return {
            ...navItem,
            displayText: `${getOnlyFirstNameFromName(
              spouseName
            )}'s Durable POA`,
          };
        }
        if (navItem.id === 8) {
          return {
            ...navItem,
            displayText: `${getOnlyFirstNameFromName(name)}'s Adv Health`,
          };
        }
        if (navItem.id === 9) {
          return {
            ...navItem,
            displayText: `${getOnlyFirstNameFromName(spouseName)}'s Adv Health`,
          };
        }
        return navItem;
      });
    };

    const updatedNavigationStateWIthNames = getUpdatedNavItemsWithName(
      leftNavItems
    );
    dispatch(
      formActionCreators.updateNavigationStateWithName(
        updatedNavigationStateWIthNames
      )
    );

    const getUpdatedNavItemsForSingleWithNoCHildren = () =>
      (updatedNavigationStateWIthNames || []).filter(
        (navState) =>
          navState.id !== 5 && navState.id !== 7 && navState.id !== 9
      );

    const getUpdatedNavItemsForSingleWithChildren = () =>
      (updatedNavigationStateWIthNames || []).filter(
        (navState) => navState.id !== 7 && navState.id !== 9
      );

    const getUpdatedNavItemsForMarriedWithChildren = () =>
      updatedNavigationStateWIthNames;

    const getUpdatedNavItemsForMarriedWithNoChildren = () =>
      (updatedNavigationStateWIthNames || []).filter(
        (navState) => navState.id !== 5
      );

    // const getUpdatedNavItemsForWithoutMinorChildren = () =>
    //   (updatedNavigationStateWIthNames || []).filter(
    //     (navState) => navState.id !== 5
    //   );

    let updatedLeftNavItems = updatedNavigationStateWIthNames;
    if (
      addressDetails?.maritalStatus === "single" &&
      addressDetails?.isChildrenAvailable === "yes"
    ) {
      updatedLeftNavItems = [...getUpdatedNavItemsForSingleWithChildren()];
    }
    if (
      addressDetails?.maritalStatus === "single" &&
      addressDetails?.isChildrenAvailable === "no"
    ) {
      updatedLeftNavItems = [...getUpdatedNavItemsForSingleWithNoCHildren()];
    }
    if (
      addressDetails?.maritalStatus === "married" &&
      addressDetails?.isChildrenAvailable === "no"
    ) {
      updatedLeftNavItems = [...getUpdatedNavItemsForMarriedWithNoChildren()];
    }
    if (
      addressDetails?.maritalStatus === "married" &&
      addressDetails?.isChildrenAvailable === "yes"
    ) {
      updatedLeftNavItems = [...getUpdatedNavItemsForMarriedWithChildren()];
    }
    if (minorChildrenCount <= 0) {
      updatedLeftNavItems = (updatedLeftNavItems || []).filter(
        (navState) => navState.id !== 5
      );
    }

    dispatch(formActionCreators.updateNavigationState(updatedLeftNavItems));
  }, [navItems]);

  const addQuestion = () => {
    if (newQuestion) {
      const newQuestions = [...questions, newQuestion];
      dispatch(formActionCreators.postQuestions(newQuestions));
      setNewQuestion("");
    }
  };

  const deleteQuestion = (question) => {
    const newQuestions = [...questions];
    const index = newQuestions.indexOf(question);
    if (index > -1) {
      newQuestions.splice(index, 1);
    }
    dispatch(formActionCreators.postQuestions(newQuestions));
  };

  const getActiveNavItem = (navItemId) =>
    leftNavItems.find((navItem) => navItem.id === navItemId);

  const activeNavItem = getActiveNavItem(navItemsState.navItems?.activeItem);

  const onSavedNameDragStart = (e, savedName) => {
    e.dataTransfer.setData("savedName", JSON.stringify(savedName));
  };
  const { picture } = authActions.getUserInfo();

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const getUpdatedSavedNames = () =>
    (savedNames || []).filter((savedName) => {
      if (savedName.dob) {
        const age = calculateAge(new Date(savedName.dob));
        if (age < 18) {
          return false;
        }
        return true;
      }
      return true;
    });

  const goToNextNavItem = () => {
    setTimeout(() => {
      const activeNavItemIndex = (navigationState || []).findIndex(
        (nav) => nav.id === navItems.activeItem
      );
      if (activeNavItemIndex !== -1) {
        const nextActiveNavItemIndex = activeNavItemIndex + 1;
        const nextActiveItemId = navigationState[nextActiveNavItemIndex]?.id;
        const presentActiveItemId = navigationState[activeNavItemIndex]?.id;
        if (nextActiveItemId) {
          dispatch(
            formActionCreators.postNavItems({
              ...navItems,
              activeItem: nextActiveItemId,
              completedItems: (navItems.completedItems || []).includes(
                presentActiveItemId
              )
                ? [...(navItems.completedItems || [])]
                : [...(navItems.completedItems || []), presentActiveItemId],
            })
          );
        }
      }
    }, 0);
  };

  return (
    <div className="form-wrapper">
      <div className="form-header-wrapper">
        <FormHeader
          setShowSavedNames={setShowSavedNames}
          setShowQuestions={setShowQuestions}
          showSavedNames={showSavedNames}
          showQuestions={showQuestions}
        />
      </div>
      <Divider />
      <div className="form-body-wrapper">
        <div className="form-body">
          {userInfoState.isLoading || navItemsState.isLoading ? (
            <div className="form-body-spinner">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <div className="left-navigation-wrapper">
                <LeftNavigation />
              </div>

              <div className="form-body-main">
                {activeNavItem?.component && (
                  <activeNavItem.component goToNextNavItem={goToNextNavItem} />
                )}
              </div>
              <div className="form-questions-names">
                {showQuestions && (
                  <FormCard>
                    <div className="form-saved-names">
                      <div className="form-saved-names-header">
                        <img src={chartIconActive} alt="" />
                        <span>Your questions</span>
                      </div>
                      <div className="savednames-wrapper">
                        <div className="questions">
                          {(questions || []).map((question) => (
                            <div className="question">
                              <img
                                src={trashIcon}
                                alt=""
                                onClick={() => deleteQuestion(question)}
                                onKeyPress={() => deleteQuestion(question)}
                                role="button"
                                tabIndex={0}
                              />
                              <div className="question-text-pic">
                                <img src={picture} alt="" />
                                {question}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="questions-input">
                          <div className="question-input">
                            <input
                              type="text"
                              value={newQuestion}
                              onChange={(e) => setNewQuestion(e.target.value)}
                              placeholder="Type your text here"
                            />
                          </div>
                          <div className="question-submit">
                            <img
                              src={questionSubmitIcon}
                              alt=""
                              onClick={addQuestion}
                              onKeyPress={addQuestion}
                              tabIndex="0"
                              role="button"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormCard>
                )}
                {showSavedNames && (
                  <FormCard>
                    <div className="form-saved-names">
                      <div className="form-saved-names-header">
                        <img src={SaveIcon} alt="" />
                        <span>Drag & drop “Saved Names”</span>
                      </div>
                      <div className="savednames-wrapper">
                        {(getUpdatedSavedNames() || []).map(
                          (savedName, index) => (
                            <div
                              key={`${index + 1}`}
                              className="savednames-name"
                              draggable="true"
                              onDragStart={(e) =>
                                onSavedNameDragStart(e, {
                                  firstName: savedName.firstName || "",
                                  lastName: savedName.lastName || "",
                                  dob: savedName.dob || "",
                                  phone: savedName.phone || "",
                                  email: savedName.email || "",
                                })
                              }
                            >
                              <span>
                                {`${savedName.firstName} ${savedName.lastName}`.trim()}
                              </span>
                              <img src={DragIcon} alt="" />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </FormCard>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Divider />
      <div className="form-footer-wrapper">
        <div className="form-footer" />
      </div>
    </div>
  );
};
