import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import NewSavedName from "../../../components/NewSavedName";
import FormCard from "../../../components/FormCard";
import FormInput from "../../../components/FormInput";
import FormRadio from "../../../components/FormRadio";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import { formActionCreators } from "../../../actions/form.actions";

const executorsSchema = yup.object().shape({
  isSameAsTrustees: yup.string().required("This option is required"),
  firstExecutor: yup.string(),
  secondExecutor: yup.string(),
});

export default ({ goToNextNavItem }) => {
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { executors } = userInfo || {};
  const { isSameAsTrustees, firstExecutor, secondExecutor, ...restExecutors } =
    executors || {};

  const {
    register,
    setValue,
    errors,
    handleSubmit,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(executorsSchema),
    defaultValues: {
      ...restExecutors,
      isSameAsTrustees: isSameAsTrustees || "yes",
      firstExecutor: firstExecutor || "",
      secondExecutor: secondExecutor || "",
    },
  });

  const watchIsSameAsTrustees = watch("isSameAsTrustees");

  const dispatch = useDispatch();
  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onExectorsSubmit = (data) => {
    dispatch(
      formActionCreators.postFormData({
        executors: data,
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        executors: data,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const onSavedNameSubmit = ({ name, data }) => {
    if (name === "firstExecutor") {
      setValue("firstExecutor", `${data.firstName} ${data.lastName}`);
    }
    if (name === "secondExecutor") {
      setValue("secondExecutor", `${data.firstName} ${data.lastName}`);
    }
  };

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`${itemIndex}`, `${savedName.firstName} ${savedName.lastName}`);
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };

  return (
    <div className="executors-wrapper">
      <FormCard>
        <span className="form-header-main-title">Executors</span>
        <span className="form-title-desc">
          Your Will should not be used if you keep all your stuff in your new
          Trust. The Executors, or Executrixes if female (good Jeopardy trivia
          question), carry out the wishes in the Will. Most people select the
          same people as the Successor Trustee since the basically do the same
          things.
        </span>
      </FormCard>
      <form onSubmit={handleSubmit(onExectorsSubmit)}>
        <FormCard>
          {/* <span className="form-header-title">Confirmation</span> */}
          <div className="form-controls">
            <FormRadio
              name="isSameAsTrustees"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              label="Would you like to have same people as Trustee?"
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        {watchIsSameAsTrustees === "no" && (
          <>
            <FormCard>
              <span className="form-header-title">First Executor</span>
              <div className="form-controls">
                <div
                  className={`form-controls-children ${
                    dragOver === "firstExecutor"
                      ? "form-controls-children-drag-over"
                      : ""
                  }`}
                  onDrop={(e) => onDropSavedNames(e, "firstExecutor")}
                  onDragOver={(e) => onDragOverSavedNames(e, "firstExecutor")}
                  onDragLeave={() => setDragover(null)}
                >
                  <FormInput
                    // label="First Executor"
                    name="firstExecutor"
                    error={errors.firstExecutor?.message}
                    inputRef={register}
                    placeholder="Drag drop names from “Saved name” list"
                    onBlur={triggerAutoSave}
                    readOnly
                  />
                </div>
                <span className="form-input-footer">
                  Name not on the “Saved Names”?{" "}
                  <span
                    onClick={() => setOpenNewSavedName("firstExecutor")}
                    onKeyPress={() => setOpenNewSavedName("firstExecutor")}
                    role="button"
                    tabIndex={0}
                  >
                    Click here
                  </span>
                </span>
              </div>
            </FormCard>
            {openNewSavedName === "firstExecutor" && (
              <NewSavedName
                title="New Executor"
                onSavedNameSubmit={onSavedNameSubmit}
                openNewSavedName={openNewSavedName}
                onClose={() => setOpenNewSavedName("")}
              />
            )}
            <FormCard>
              <span className="form-header-title">Second Executor</span>
              <div className="form-controls">
                <div
                  className={`form-controls-children ${
                    dragOver === "secondExecutor"
                      ? "form-controls-children-drag-over"
                      : ""
                  }`}
                  onDrop={(e) => onDropSavedNames(e, "secondExecutor")}
                  onDragOver={(e) => onDragOverSavedNames(e, "secondExecutor")}
                  onDragLeave={() => setDragover(null)}
                >
                  <FormInput
                    // label="Second Executor"
                    name="secondExecutor"
                    error={errors.secondExecutor?.message}
                    inputRef={register}
                    placeholder="Drag drop names from “Saved name” list"
                    onBlur={triggerAutoSave}
                    readOnly
                  />
                </div>
                <span className="form-input-footer">
                  Name not on the “Saved Names”?{" "}
                  <span
                    onClick={() => setOpenNewSavedName("secondExecutor")}
                    onKeyPress={() => setOpenNewSavedName("secondExecutor")}
                    role="button"
                    tabIndex={0}
                  >
                    Click here
                  </span>
                </span>
              </div>
            </FormCard>
          </>
        )}
        {openNewSavedName === "secondExecutor" && (
          <NewSavedName
            title="New Executor"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
