import React from "react";
import FormCard from "../FormCard";

import "./UserInfoContinueCard.scss";

export default ({ name }) => (
  <FormCard>
    <span className="continue-card-name">{name}</span>
    {/* <span className="continue-card-desc">
      Please continue with filling up the form..
    </span> */}
  </FormCard>
);
