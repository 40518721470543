import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import AuthContext from "../../AuthContext";
import authParams from "../../auth0-param";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import AuthWrapper from "../../components/AuthWrapper";
import FormError from "../../components/FormError";
import { setUserIDForTracker } from "../../tracker";
import "./Signin.scss";

export default () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signinFormState, setSigninFormState] = useState({
    isLoading: false,
    errorMessage: "",
  });
  const { auth0Client, authActions } = useContext(AuthContext);

  const history = useHistory();

  const submitSignin = (e) => {
    e.preventDefault();
    if (!username) {
      setUserNameError("* UserName cannot be empty");
    }
    if (!password) {
      setPasswordError("* Password cannot be empty");
    }
    if (username && password) {
      setUserNameError("");
      setPasswordError("");
      setSigninFormState({
        ...signinFormState,
        isLoading: true,
        errorMessage: "",
      });
      auth0Client.client.login(
        {
          realm: authParams.realm,
          username,
          password,
        },
        (err, authResult) => {
          if (err) {
            setSigninFormState({
              ...signinFormState,
              isLoading: false,
              errorMessage: err.description,
            });
            return;
          }
          if (authResult) {
            authActions.setAuthSession(authResult);
            auth0Client.client.userInfo(
              authResult.accessToken,
              (error, user) => {
                const { sub } = user;
                if (error) {
                  return;
                }
                if (user) {
                  authActions.setUserInfo(user);
                  setSigninFormState({
                    ...signinFormState,
                    isLoading: false,
                  });
                  history.push("/form");

                  setUserIDForTracker({ userId: sub });
                }
              }
            );
          }
        }
      );
    }
  };

  const forgotPassword = () => {
    history.push("/forgotpassword");
  };

  const onSignupClick = () => {
    history.push("/signup");
  };

  return (
    <AuthWrapper page="signin">
      <span className="signin-welcome">Welcome Back</span>
      <span className="signin-text">
        Sign in to continue to LivingTrust for your Will plannning
      </span>
      <form className="signin-form">
        {signinFormState.errorMessage && (
          <div className="signin-form-error">
            <FormError error={signinFormState.errorMessage} />
          </div>
        )}
        <FormInput
          type="email"
          label="Email"
          onChange={(e) => setUserName(e.target.value)}
          value={username}
          error={userNameError}
        />
        <FormInput
          type="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          error={passwordError}
        />
        <Button
          type="submit"
          className="sigin-button"
          onClick={submitSignin}
          isLoading={signinFormState.isLoading}
        >
          Sign In
        </Button>
        <div className="signin-form-footer">
          <span>
            <span
              className="highlight-text"
              onClick={forgotPassword}
              onKeyDown={forgotPassword}
              role="button"
              tabIndex={0}
            >
              Forgot password?
            </span>
          </span>
          <span>
            New to Living Trust?{" "}
            <span
              className="highlight-text"
              role="button"
              tabIndex={0}
              onClick={onSignupClick}
              onKeyPress={onSignupClick}
            >
              Sign Up Now
            </span>
          </span>
        </div>
      </form>
    </AuthWrapper>
  );
};
