/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.scss";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="confirm-user-information">
        <h4>CONGRATULATIONS!</h4>
        <p>
          You have submitted your information and you can no longer make changes
          in the online form. You will be contacted shortly for the next steps
          and to set up your meeting with the lawyer. You can make any changes
          and ask all your questions during your lawyer meeting. If you have any
          questions immediately you are always welcome to use the contact page,
          or call and talk to a human at our office.
        </p>
        <div className="modal-contact">
          Need help? <a href="/#contact">Contact us</a>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
