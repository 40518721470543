import React, { useState } from "react";
import AddressDetails from "./AddressDetails";
import SpouseDetails from "./SpouseDetails";
import ChildrenDetails from "./ChildrenDetails";
import PriorMarriageChildrenDetails from "./PriorMarriageChildrenDetails";
import DeceasedChildrenDetails from "./DeceasedChildrenDetails";
import VideoDetails from "./VideoDetails";

export default ({ goToNextNavItem }) => {
  const [activeUserInformSubItems, setActiveUserInfomSubItems] = useState([
    "addressDetails",
  ]);

  const activeUserInformSubItem =
    activeUserInformSubItems[activeUserInformSubItems.length - 1];

  const goBackSubItem = () => {
    setActiveUserInfomSubItems(
      activeUserInformSubItems.slice(0, activeUserInformSubItems.length - 1)
    );
  };

  return (
    <div>
      {activeUserInformSubItem === "addressDetails" && (
        <AddressDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
      {activeUserInformSubItem === "spouseDetails" && (
        <SpouseDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
      {activeUserInformSubItem === "childrenDetails" && (
        <ChildrenDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
      {activeUserInformSubItem === "priorMarriageChildrenDetails" && (
        <PriorMarriageChildrenDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
      {activeUserInformSubItem === "deceasedChildrenDetails" && (
        <DeceasedChildrenDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
      {activeUserInformSubItem === "videoDetails" && (
        <VideoDetails
          setActiveUserInfomSubItems={setActiveUserInfomSubItems}
          activeUserInformSubItems={activeUserInformSubItems}
          goToNextNavItem={goToNextNavItem}
          goBackSubItem={goBackSubItem}
        />
      )}
    </div>
  );
};
