/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import auth0 from "auth0-js";
import authParams from "./auth0-param";
import AuthContext from "./AuthContext";
import Tracker from "./tracker";

import Home from "./pages/Home";
import Form from "./pages/Form";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import VerifyEmail from "./pages/VerifyEmail";
import SignUpConfirm from "./pages/SignupConfirm";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Protected from "./pages/Protected";
import ProtectedRoute from "./ProtectedRoute";
// import Teamplate from "./pages/Template";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

const initialAuthState = {
  isAuthenticated: false,
  user: null,
};

const authStateReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export const authActions = {
  isAuthenticated: () => {
    const tokenExpiresAt = JSON.parse(localStorage.getItem("expires_at"));
    if (new Date().getTime() < tokenExpiresAt) {
      return true;
    }
    return false;
  },
  setAuthSession: (authResult) => {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  },
  setUserInfo: (user) => {
    const { name, email, picture, sub } = user;
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("picture", picture);
    localStorage.setItem("sub", sub);
  },
  getUserInfo: () => ({
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    picture: localStorage.getItem("picture"),
    sub: localStorage.getItem("sub"),
  }),
  signOut: () => {
    localStorage.clear();
    window.location.reload();
  },
};

console.log("authParams", authParams);

function App() {
  Tracker();

  const auth0Client = new auth0.WebAuth({
    domain: authParams.domain,
    clientID: authParams.clientId,
    audience: authParams.apiAudience,
    redirectUri: authParams.callbackUrl,
    scope: authParams.scope,
    responseType: "token id_token",
  });

  const [authState, setAuthState] = React.useReducer(
    authStateReducer,
    initialAuthState
  );

  useEffect(() => {
    function handleMouseDownOnce() {
      document.body.classList.remove("user-is-tabbing");

      window.removeEventListener("mousedown", handleMouseDownOnce);
      window.addEventListener("keydown", handleFirstTab);
    }

    function handleFirstTab(e) {
      if (e.keyCode === 9) {
        document.body.classList.add("user-is-tabbing");

        window.removeEventListener("keydown", handleFirstTab);
        window.addEventListener("mousedown", handleMouseDownOnce);
      }
    }

    window.addEventListener("keydown", handleFirstTab);
  }, []);

  return (
    <div className="App">
      <AuthContext.Provider
        value={{ authState, setAuthState, auth0Client, authActions }}
      >
        <Switch>
          <Route path="/signin" exact>
            <Signin />
          </Route>
          <Route path="/signup" exact>
            <Signup />
          </Route>
          <Route path="/signupconfirm" exact>
            <SignUpConfirm />
          </Route>
          <Route path="/verifyemail" exact>
            <VerifyEmail />
          </Route>
          <Route path="/forgotpassword" exact>
            <ForgotPassword />
          </Route>
          <Route path="/resetpassword" exact>
            <ResetPassword />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/form">
            <Form />
          </Route>
          {/* <Route path="/template">
              <Teamplate />
            </Route> */}
          <ProtectedRoute path="/protected" exact>
            <Protected />
          </ProtectedRoute>
        </Switch>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
