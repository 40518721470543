import React, { Fragment } from "react";
import "./Radio.scss";

export default ({ name, options, inputRef, onBlur, onChange }) => (
  <div className="radio-wrapper">
    {options.map((option) => (
      <Fragment key={option.value}>
        <input
          name={name}
          type="radio"
          value={option.value}
          id={`${name}${option.value}`}
          ref={inputRef}
          onBlur={onBlur}
          onChange={onChange}
        />
        <label htmlFor={`${name}${option.value}`}>{option.label}</label>
      </Fragment>
    ))}
  </div>
);
