/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import FormCard from "../../../components/FormCard";
import expandIcon from "../../../assets/images/expandIcon.svg";
import collapseIcon from "../../../assets/images/collapseIcon.svg";
import chevronRight from "../../../assets/images/chevrons-right.svg";
import { formActionCreators } from "../../../actions/form.actions";
import { getDateInUSFormat } from "../../../helpers";
import "./InfoConfirmation.scss";

export default ({ goToNextNavItem }) => {
  const navigationStateWithName = useSelector(
    (state) => state.navigationStateWithName
  );
  const navigationState = useSelector((state) => state.navigationState);
  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState || {};
  const {
    userInformation,
    benificiaries,
    trustees,
    executors,
    guardianOfMinors,
    durablePowerOfAttorney,
    spouseDurablePowerOfAttorney,
    advancedHealthCare,
    spouseAdvancedHealthCare,
    assetsDetails,
    userInfoConfirmed,
  } = userInfo || {};
  const [exapndedItems, setExpandedItems] = useState([
    navigationStateWithName[0].key,
  ]);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const onExpandCollapseClick = (navItemKey) => {
    if (exapndedItems.indexOf(navItemKey) !== -1) {
      setExpandedItems([
        ...exapndedItems.filter((item) => item !== navItemKey),
      ]);
    } else {
      setExpandedItems([...exapndedItems, navItemKey]);
    }
  };

  const getSentenceFromCamelCase = (text) => {
    const result = text?.replace(/([A-Z])/g, " $1");
    return result?.charAt(0)?.toUpperCase() + result?.slice(1);
  };

  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const dispatch = useDispatch();
  const onInformConfirmSubmit = () => {
    dispatch(
      formActionCreators.postFormData({
        userInfoConfirmed: true,
      })
    );
    dispatch(formActionCreators.submitUserInfo());

    setShowConfirmModel(true);
  };

  const getDisplayCardDetails = (navItem) => {
    if (navItem.key === "userInformation") {
      const allChildren = [
        ...(userInformation?.childrenDetails?.children || []),
        ...(userInformation?.priorMarriageChildrenDetails?.children || []),
        ...(userInformation?.deceasedChildrenDetails?.children || []),
      ];
      return (
        <>
          <div className="display-card-detils">
            <div>
              <span>Address</span>
              <span>{userInformation.addressDetails.address}</span>
            </div>
            <div>
              <span>City</span>
              <span>{userInformation.addressDetails.city}</span>
            </div>
            <div>
              <span>County</span>
              <span>{userInformation.addressDetails.county}</span>
            </div>
            <div>
              <span>State</span>
              <span>{userInformation.addressDetails.state}</span>
            </div>
            <div>
              <span>Zip Code</span>
              <span>{userInformation.addressDetails.zipCode}</span>
            </div>
            <div>
              <span>Phone</span>
              <span>{userInformation.addressDetails.phone}</span>
            </div>
            <div>
              <span>DOB</span>
              <span>
                {getDateInUSFormat(userInformation.addressDetails.dob)}
              </span>
            </div>
            <div>
              <span>Gender</span>
              <span>{userInformation.addressDetails.gender}</span>
            </div>
            <div>
              <span>US Citizen?</span>
              <span>{userInformation.addressDetails.isUsCitizen}</span>
            </div>
            <div>
              <span>Status</span>
              <span>{userInformation.addressDetails.maritalStatus}</span>
            </div>
            <div>
              <span>Number of property</span>
              <span>{userInformation.addressDetails.properties || 0}</span>
            </div>
            <div>
              <span>Number Of Children</span>
              <span>{userInformation.addressDetails.noOfChildren || 0}</span>
            </div>
          </div>
          {userInformation.addressDetails.maritalStatus === "married" && (
            <>
              <div className="display-carddetails-divider" />
              <div className="display-card-detils">
                <div>
                  <span>Spouse/ Partner Name</span>
                  <span>
                    {`${userInformation.spouseDetails.firstName} ${userInformation.spouseDetails.lastName}`.trim()}
                  </span>
                </div>
                <div>
                  <span>Address</span>
                  <span>{userInformation.spouseDetails.address}</span>
                </div>
                <div>
                  <span>City</span>
                  <span>{userInformation.spouseDetails.city}</span>
                </div>
                <div>
                  <span>County</span>
                  <span>{userInformation.spouseDetails.county}</span>
                </div>
                <div>
                  <span>State</span>
                  <span>{userInformation.spouseDetails.state}</span>
                </div>
                <div>
                  <span>Zip Code</span>
                  <span>{userInformation.spouseDetails.zipCode}</span>
                </div>
                <div>
                  <span>Phone</span>
                  <span>{userInformation.spouseDetails.phone}</span>
                </div>
                <div>
                  <span>DOB</span>
                  <span>
                    {getDateInUSFormat(userInformation.spouseDetails.dob)}
                  </span>
                </div>
                <div>
                  <span>Email</span>
                  <span>{userInformation.spouseDetails.email}</span>
                </div>
                <div>
                  <span>Gender</span>
                  <span>{userInformation.spouseDetails.gender}</span>
                </div>
                <div>
                  <span>US Citizen?</span>
                  <span>{userInformation.spouseDetails.isUsCitizen}</span>
                </div>
                <div>
                  <span>Prenuptial Agreement</span>
                  <span>
                    {userInformation.spouseDetails.prenuptialAgreement}
                  </span>
                </div>
                <div>
                  <span>Separate Property</span>
                  <span>{userInformation.spouseDetails.seperateProperty}</span>
                </div>
              </div>
            </>
          )}
          {userInformation.addressDetails.isChildrenAvailable === "yes" && (
            <>
              <div className="display-carddetails-divider" />
              <div className="display-card-detils">
                <div>
                  <span>Do you have children?</span>
                  <span>
                    {userInformation.addressDetails.isChildrenAvailable}
                  </span>
                </div>
                <div>
                  <span>Number of Children</span>
                  <span>
                    {userInformation.addressDetails.noOfChildren || 0}
                  </span>
                </div>
                {allChildren.map((child, index) => {
                  return (
                    <>
                      <div key={index}>
                        <span>{`Child ${index + 1}`}</span>
                        <span>
                          {`${child.firstName} ${child.lastName}`.trim()}
                        </span>
                      </div>
                      <div key={index}>
                        <span>DOB</span>
                        <span>{getDateInUSFormat(child.dob)}</span>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </>
      );
    }
    if (navItem.key === "benificiaries") {
      const AllbenificiariesAplit = [
        ...(benificiaries.extraBenificiariesSplit || []),
        ...(benificiaries.childBenificiariesSplit || []),
      ];
      return (
        <div className="display-card-detils">
          {(AllbenificiariesAplit || []).map((ben) => {
            return (
              <>
                {(ben.name || ben.splitPercent) && (
                  <>
                    <div>
                      <span>Name</span>
                      <span>{ben.name}</span>
                    </div>
                    <div>
                      <span>Split Percent</span>
                      <span>{ben.splitPercent}</span>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      );
    }
    if (navItem.key === "trustees") {
      return (
        <div className="display-card-detils">
          <div>
            <span>First Successor Trustee</span>
            <span>{trustees.firstTrustee}</span>
          </div>
          <div>
            <span>Second Successor Trustee</span>
            <span>{trustees.secondTrustee}</span>
          </div>
        </div>
      );
    }
    if (navItem.key === "executors") {
      return (
        <div className="display-card-detils">
          {executors.isSameAsTrustees === "yes" ? (
            <>
              <div>
                <span>First Executor</span>
                <span>{trustees.firstTrustee}</span>
              </div>
              <div>
                <span>Second Executor</span>
                <span>{trustees.secondTrustee}</span>
              </div>
            </>
          ) : (
            <>
              <div>
                <span>First Executor</span>
                <span>{executors.firstExecutor}</span>
              </div>
              <div>
                <span>Second Executor</span>
                <span>{executors.secondExecutor}</span>
              </div>
            </>
          )}
        </div>
      );
    }
    if (navItem.key === "guardianOfMinors") {
      return (
        <div className="display-card-detils">
          <div>
            <span>Primary Guardian Of Minor</span>
            <span>{guardianOfMinors.primaryGaurdianOfMinor}</span>
          </div>
          <div>
            <span>Secondary Guardian of Minor</span>
            <span>{guardianOfMinors.secondaryGaurdianOfMinor}</span>
          </div>
          <div>
            <span>Interim/ Temporary Guardian of Minor</span>
            <span>{guardianOfMinors.localGaurdianOfMinor}</span>
          </div>
        </div>
      );
    }
    if (navItem.key === "durablePowerOfAttorney") {
      return (
        <div className="display-card-detils">
          <div>
            <span>First Power of Attorney</span>
            <span>{durablePowerOfAttorney.firstPowerOfAttorney}</span>
          </div>
          <div>
            <span>Second Power of Attorney</span>
            <span>{durablePowerOfAttorney.secondPowerOfAttorney}</span>
          </div>
          <div>
            <span>Attorney Powers</span>
            {durablePowerOfAttorney.attorneyPowers.map((power) => (
              <span>{power}</span>
            ))}
          </div>
        </div>
      );
    }
    if (
      navItem.key === "spouseDurablePowerOfAttorney" &&
      userInformation.addressDetails.maritalStatus === "married"
    ) {
      return (
        <div className="display-card-detils">
          <div>
            <span>Spouse First Power of Attorney</span>
            <span>{spouseDurablePowerOfAttorney.firstPowerOfAttorney}</span>
          </div>
          <div>
            <span>Spouse Second Power of Attorney</span>
            <span>{spouseDurablePowerOfAttorney.secondPowerOfAttorney}</span>
          </div>
          <div>
            <span>Spouse Attorney Powers</span>
            {spouseDurablePowerOfAttorney.attorneyPowers.map((power) => (
              <span>{power}</span>
            ))}
          </div>
        </div>
      );
    }
    if (navItem.key === "advancedHealthCare") {
      return (
        <div className="display-card-detils">
          <div>
            <span>First Advanced Health Care</span>
            <span>{advancedHealthCare.firstAdvanceHealthCare}</span>
          </div>
          <div>
            <span>Second Advanced Health Care</span>
            <span>{advancedHealthCare.secondAdvanceHealthCare}</span>
          </div>
        </div>
      );
    }
    if (
      navItem.key === "spouseAdvancedHealthCare" &&
      userInformation.addressDetails.maritalStatus === "married"
    ) {
      return (
        <div className="display-card-detils">
          <div>
            <span>Spouse First Advanced Health Care</span>
            <span>{spouseAdvancedHealthCare.firstAdvanceHealthCare}</span>
          </div>
          <div>
            <span>Spouse Second Advanced Health Care</span>
            <span>{spouseAdvancedHealthCare.secondAdvanceHealthCare}</span>
          </div>
        </div>
      );
    }
    if (navItem.key === "assetsDetails") {
      return (
        <>
          <div className="display-card-detils">
            {assetsDetails.propertyDetails.map((property, index) => {
              return (
                <>
                  <div>
                    <span>{`Property file-${index + 1}`}</span>
                    <span>{property.fileName}</span>
                  </div>
                  <div>
                    <span>Address</span>
                    <span>{property.address}</span>
                  </div>
                  <div>
                    <span>City</span>
                    <span>{property.city}</span>
                  </div>
                  <div>
                    <span>State</span>
                    <span>{property.state}</span>
                  </div>
                  <div>
                    <span>Zip Code</span>
                    <span>{property.zipCode}</span>
                  </div>
                </>
              );
            })}
          </div>
          {assetsDetails.retirementAccounts.map((retAccount, index) => (
            <>
              <div className="display-carddetails-divider" />
              <div className="display-card-detils">
                <>
                  <div>
                    <span>{`Institution Name - ${index + 1}`}</span>
                    <span>{retAccount?.institutionName || ""}</span>
                  </div>
                  <div>
                    <span>Type</span>
                    <span>{retAccount?.type || ""}</span>
                  </div>
                  <div>
                    <span>Account Number</span>
                    <span>{retAccount?.accountNumber || ""}</span>
                  </div>
                  <div>
                    <span>Amount</span>
                    <span>{retAccount?.amount || ""}</span>
                  </div>
                </>
              </div>
            </>
          ))}
          <div className="display-carddetails-divider" />
          <div className="display-card-detils">
            <div>
              <span>Other Assets</span>
              <span>{assetsDetails?.otherAssets || ""}</span>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className="info-confirm-wrapper">
      <FormCard>
        <span className="form-header-main-title">Information confirmation</span>
        <span className="form-title-desc">
          Review or change any responses can be done after meeting with your
          attorney
        </span>
      </FormCard>
      {navigationState.map((navItem) => {
        if (navItem.key) {
          return (
            <div className="expand-card">
              <span className="card-details-title">
                {getSentenceFromCamelCase(navItem.displayText)}
              </span>
              <img
                className="expand-icon"
                src={
                  exapndedItems.includes(navItem.key)
                    ? collapseIcon
                    : expandIcon
                }
                alt=""
                onClick={() => onExpandCollapseClick(navItem.key)}
              />
              <div>
                {exapndedItems.includes(navItem.key) &&
                  getDisplayCardDetails(navItem)}
              </div>
            </div>
          );
        }
        return "";
      })}
      <div className="form-details-buttons">
        {!userInfoConfirmed && (
          <Button className="next-button" onClick={onInformConfirmSubmit}>
            Submit
            <img src={chevronRight} alt="" />
          </Button>
        )}
      </div>
      {showConfirmModel && (
        <Modal
          show={showConfirmModel}
          onHide={() => setShowConfirmModel(false)}
        />
      )}
    </div>
  );
};
