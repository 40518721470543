import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormCard from "../../../../components/FormCard";
import UserInfoContinueCard from "../../../../components/UserInfoContinueCard";
import FormInput from "../../../../components/FormInput";
import NextButton from "../../../../components/NextButton";
import BackButton from "../../../../components/BackButton";
import FormRadio from "../../../../components/FormRadio";
import { formActionCreators } from "../../../../actions/form.actions";
import { checkIfNamePresentInSavedNames } from "../../../../helpers";

const childrenSchema = {
  firstName: yup.string(),
  lastName: yup.string(),
  dob: yup.string(),
};

const priorMarriageChildrenSchema = yup.object().shape({
  children: yup.array().of(yup.object().shape(childrenSchema)),
  priorMarriageChildrenBelongsTo: yup
    .string()
    .required("This field is required"),
});

export default ({
  setActiveUserInfomSubItems,
  activeUserInformSubItems,
  goBackSubItem,
}) => {
  const [dragOver, setDragover] = useState(null);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { userInformation } = userInfo || {};
  const { childrenDetails, priorMarriageChildrenDetails } =
    userInformation || {};
  const { priorMarriageChildren, hasDeceasedChild } = childrenDetails || {};

  const {
    children,
    priorMarriageChildrenBelongsTo,
    ...restPriorMarriageChildrenDetails
  } = priorMarriageChildrenDetails || {};

  const defaultChildren = [...new Array(priorMarriageChildren)].map(() => ({
    firstName: "",
    lastName: "",
    dob: "",
  }));

  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(priorMarriageChildrenSchema),
    defaultValues: {
      ...restPriorMarriageChildrenDetails,
      children: (children || []).length > 0 ? children : defaultChildren,
      priorMarriageChildrenBelongsTo:
        priorMarriageChildrenBelongsTo || "spouse",
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "children",
  });

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    setValue(`children[${itemIndex}].firstName`, savedName.firstName || "");
    setValue(`children[${itemIndex}].lastName`, savedName.lastName || "");
    setValue(`children[${itemIndex}].dob`, savedName.dob || "");
    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };
  const watchChildren = watch("children");
  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = () => {
    const newNames = [];
    (watchChildren || []).forEach((child) => {
      if (child.firstName || child.lastName) {
        const childFound = checkIfNamePresentInSavedNames(savedNames, {
          firstName: child.firstName,
          lastName: child.lastName,
        });
        if (!childFound) {
          newNames.push({ ...child });
        }
      }
    });
    if ((newNames || []).length > 0) {
      dispatch(formActionCreators.postSavedNames([...savedNames, ...newNames]));
    }
  };

  const onPriorMarriageChildrenDetailsSubmit = (data) => {
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          priorMarriageChildrenDetails: data,
        },
      })
    );
    saveNamesToSavedNames();
    if (hasDeceasedChild === "yes") {
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "deceasedChildrenDetails",
      ]);
    } else {
      setActiveUserInfomSubItems([...activeUserInformSubItems, "videoDetails"]);
    }
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          priorMarriageChildrenDetails: data,
        },
      })
    );
  };

  return (
    <div>
      <UserInfoContinueCard name="Please tell us about your Child[ren]" />
      <form onSubmit={handleSubmit(onPriorMarriageChildrenDetailsSubmit)}>
        <FormCard>
          <span className="form-title">
            Children from prior marriage/ Partner
          </span>
          {fields.map((item, index) => (
            <div
              className={`form-controls-children ${
                dragOver === index ? "form-controls-children-drag-over" : ""
              }`}
              key={item.id}
              onDrop={(e) => onDropSavedNames(e, index)}
              onDragOver={(e) => onDragOverSavedNames(e, index)}
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                label="First Name"
                name={`children[${index}].firstName`}
                error={errors?.children?.[index]?.firstName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.firstName}
              />
              <FormInput
                label="Last Name"
                name={`children[${index}].lastName`}
                error={errors?.children?.[index]?.lastName?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.lastName}
              />
              <FormInput
                label="DOB"
                name={`children[${index}].dob`}
                type="date"
                error={errors?.children?.[index]?.dob?.message || ""}
                inputRef={register()}
                onBlur={triggerAutoSave}
                defaultValue={item.dob}
              />
            </div>
          ))}
          <div className="form-controls">
            <FormRadio
              label="Child from prior relationship belongs to which partner?"
              name="priorMarriageChildrenBelongsTo"
              options={[
                { value: "me", label: `${profile.name}` },
                { value: "spouse", label: `${profile.name}'s spouse` },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBackSubItem} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
