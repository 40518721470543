import React from "react";

import Video from "../../../components/Video";
import FLTFinal from "../../../assets/videos/FLT_Final.mp4";
import journeyMap from "../../../assets/images/journeyMap.png";
import "./VideoSections.scss";

export default () => (
  <div className="video-section">
    {/* <span className="video-sec-tag">WATCH OUR PROCESS</span>
    <span className="video-sec-title">Making Estate planning easy</span>
    <div className="video-sec-title-desc">
      Enter your information and preferences, talk to live attorneys, and fund
      your trust 100% with Zero contact.
    </div> */}
    <div className="video-sec-video">
      <Video mp4source={FLTFinal} />
    </div>
    <div style={{ marginTop: "20px" }}>
      <img src={journeyMap} alt="" />
    </div>
    {/* <div className="video-sec-info">
      <span>
        <span>Lorem ipsum dolor sit</span>
        <span>
          Amet facilisis magna etiam tempor orci eu. Sit amet est placerat in
          egestas erat imperdiet sed.
        </span>
      </span>
      <span>
        <span>Vulputate enim nulla.</span>
        <span>
          Amet facilisis magna etiam tempor orci eu. Sit amet est placerat in
          egestas erat imperdiet sed.
        </span>
      </span>
      <span>
        <span>Rutrum tellus pellentes</span>
        <span>
          Amet facilisis magna etiam tempor orci eu. Sit amet est placerat in
          egestas erat imperdiet sed.
        </span>
      </span>
    </div> */}
  </div>
);
