import React, { useContext, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import Pricing1 from "../../../assets/images/pricing1.png";
import Pricing2 from "../../../assets/images/pricing2.png";
import Button from "../../../components/Button";
import AuthContext from "../../../AuthContext";

import "./PricingSection.scss";

export default () => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { authActions } = useContext(AuthContext);

  useLayoutEffect(() => {
    if (authActions.isAuthenticated()) {
      setIsAuthenticated(true);
    }
  }, []);

  const onSinglePersonStartClick = () => {
    if (isAuthenticated) {
      history.push({
        pathname: "/form",
        state: {
          maritalStatus: "single",
        },
      });
    } else {
      history.push("/signin");
    }
  };

  const onMarriedOersonStartClick = () => {
    if (isAuthenticated) {
      history.push({
        pathname: "/form",
        state: {
          maritalStatus: "married",
        },
      });
    } else {
      history.push("/signin");
    }
  };

  return (
    <div className="pricing-section">
      <div className="pricing-desc">
        <span>Pricing</span>
        <span>Up to two properties included</span>
        <span>$500 for each additional</span>
        <span>
          A Trust with no assets is simply not a Trust. Many attorneys and
          almost all the online solutions do not fund your Trust, or rather put
          assets in the name of the Trust. Then the Trust is not valid and is
          useless. We record transfer deeds to real property and assign other
          assets to your trust. This is no easy task. The good news is it is
          included in the one fee pricing for our Trust packages. It includes
          drafting the documents, all the real attorney face time you desire,
          notary fees, recording fees, and hard copy documents sent right to
          your door. The price includes the transfer of up to two properties.
        </span>
      </div>
      <div className="pricing-images">
        <div className="pricing-image">
          <img src={Pricing1} alt="Pricing1" />
          <div>
            <span>Single person</span>
            <span>
              Base $2500 for up to two properties +$500 for each additional
              property
            </span>
            <Button onClick={onSinglePersonStartClick}>Get started</Button>
          </div>
        </div>
        <div className="pricing-image">
          <img src={Pricing2} alt="Pricing1" />
          <div>
            <span>Married people</span>
            <span>
              Base $3500 for up to two properties +$500 for each additional
              property
            </span>
            <Button onClick={onMarriedOersonStartClick}>Get started</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
