/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NewSavedName from "../../../components/NewSavedName";
import FormCard from "../../../components/FormCard";
import FormInput from "../../../components/FormInput";
import BackButton from "../../../components/BackButton";
import NextButton from "../../../components/NextButton";
import { formActionCreators } from "../../../actions/form.actions";
import { authActions } from "../../../App";
import "./Trustees.scss";

export default ({ goToNextNavItem }) => {
  const [dragOver, setDragover] = useState(null);
  const [openNewSavedName, setOpenNewSavedName] = useState("");

  const trusteesSchema = yup.object().shape({
    firstTrustee: yup.string(),
    secondTrustee: yup.string(),
  });

  const userInfoState = useSelector((state) => state.userInfoState);
  const { userInfo } = userInfoState;
  const { trustees } = userInfo || {};

  const { userInformation } = userInfo || {};
  const { spouseDetails } = userInformation || {};

  const { firstTrustee, secondTrustee, ...restTrustees } = trustees || {};

  const { name: userName } = authActions.getUserInfo();
  const spouseName = `${spouseDetails?.firstName || ""} ${
    spouseDetails?.lastName || ""
  }`.trim();

  const {
    register,
    errors,
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(trusteesSchema),
    defaultValues: {
      ...restTrustees,
      firstTrustee: firstTrustee || "",
      secondTrustee: secondTrustee || "",
    },
  });

  const dispatch = useDispatch();
  const navItemsState = useSelector((state) => state.navItemsState);
  const { navItems } = navItemsState;

  const onTrusteesSubmit = (data) => {
    console.log("data is", data);
    dispatch(
      formActionCreators.postFormData({
        trustees: data,
      })
    );
    goToNextNavItem();
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        trustees: data,
      })
    );
  };

  const navigationState = useSelector((state) => state.navigationState);
  const goBack = () => {
    const activeNavItemIndex = (navigationState || []).findIndex(
      (nav) => nav.id === navItems.activeItem
    );
    if (activeNavItemIndex !== -1) {
      const prevActiveNavItemIndex = activeNavItemIndex - 1;
      dispatch(
        formActionCreators.postNavItems({
          ...navItems,
          activeItem: navigationState[prevActiveNavItemIndex].id,
        })
      );
    }
  };

  const onSavedNameSubmit = ({ name, data }) => {
    if (name === "firstTrustee") {
      setValue("firstTrustee", `${data.firstName} ${data.lastName}`);
    }
    if (name === "secondTrustee") {
      setValue("secondTrustee", `${data.firstName} ${data.lastName}`);
    }
  };

  const onDropSavedNames = (e, itemIndex) => {
    e.preventDefault();

    const data = e.dataTransfer.getData("savedName");
    const savedName = JSON.parse(data);
    const fullSavedName = `${savedName.firstName || ""} ${
      savedName.lastName || ""
    }`.trim();
    if (fullSavedName === userName || fullSavedName === spouseName) {
      setError(`${itemIndex}`, {
        type: "manual",
        message: "Trustees cannot be yourself or your spouse",
      });
    } else {
      setValue(`${itemIndex}`, fullSavedName);
      clearErrors(`${itemIndex}`);
    }

    setDragover(null);
  };

  const onDragOverSavedNames = (e, itemIndex) => {
    setDragover(itemIndex);
    e.preventDefault();
  };
  const data = getValues();
  console.log("data on drop is", data);

  return (
    <div className="trustees-wrapper">
      <form onSubmit={handleSubmit(onTrusteesSubmit)}>
        <FormCard>
          <span className="form-header-main-title">Successor Trustees</span>
          <span className="form-title-desc">
            A Trustee, as it says in the word, is someone you trust. The
            Successor Trustee is the person who will carry out your wishes after
            you pass away. If you are married, the Successor Trustee will not
            have any power until both of you have passed away.
          </span>
        </FormCard>
        <FormCard>
          <span className="form-header-title">First Successor Trustee</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "firstTrustee"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "firstTrustee")}
              onDragOver={(e) => onDragOverSavedNames(e, "firstTrustee")}
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="First Trustee"
                name="firstTrustee"
                error={errors.firstTrustee?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("firstTrustee")}
                onKeyPress={() => setOpenNewSavedName("firstTrustee")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "firstTrustee" && (
          <NewSavedName
            title="New Trustee"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <FormCard>
          <span className="form-header-title">Second Successor Trustee</span>
          <div className="form-controls">
            <div
              className={`form-controls-children ${
                dragOver === "secondTrustee"
                  ? "form-controls-children-drag-over"
                  : ""
              }`}
              onDrop={(e) => onDropSavedNames(e, "secondTrustee")}
              onDragOver={(e) => onDragOverSavedNames(e, "secondTrustee")}
              onDragLeave={() => setDragover(null)}
            >
              <FormInput
                // label="Second Trustee"
                name="secondTrustee"
                error={errors.secondTrustee?.message}
                inputRef={register}
                placeholder="Drag drop names from “Saved name” list"
                onBlur={triggerAutoSave}
                readOnly
              />
            </div>
            <span className="form-input-footer">
              Name not on the “Saved Names”?{" "}
              <span
                onClick={() => setOpenNewSavedName("secondTrustee")}
                onKeyPress={() => setOpenNewSavedName("secondTrustee")}
                role="button"
                tabIndex={0}
              >
                Click here
              </span>
            </span>
          </div>
        </FormCard>
        {openNewSavedName === "secondTrustee" && (
          <NewSavedName
            title="New Trustee"
            onSavedNameSubmit={onSavedNameSubmit}
            openNewSavedName={openNewSavedName}
            onClose={() => setOpenNewSavedName("")}
          />
        )}
        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBack} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
