/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import Logo from "../../../assets/images/logo.svg";
// import chartInactice from "../../../assets/images/chatInactive.svg";
// import chartActive from "../../../assets/images/chartIconActive.svg";
// import saveActive from "../../../assets/images/saveActive.svg";
// import saveInactive from "../../../assets/images/saveInactive.svg";
import { profileActionCreators } from "../../../actions/profile.actions";
import AuthContext from "../../../AuthContext";
import Button from "../../../components/Button";
import "./FormHeader.scss";

export default () =>
  //   {
  //   setShowSavedNames,
  //   setShowQuestions,
  //   showSavedNames,
  //   showQuestions,
  // }
  {
    const dispatch = useDispatch();
    const { authActions } = useContext(AuthContext);

    const profile = useSelector((state) => state.profile);
    const { name, picture } = authActions.getUserInfo();

    useEffect(() => {
      if (!profile) {
        dispatch(profileActionCreators.updateProfile({ name, picture }));
      }
    }, []);

    const signout = () => {
      authActions.signOut();
    };

    const onLogoClick = () => {
      window.location = "/";
    };

    // const onQuestionsClick = () => {
    //   setShowQuestions(!showQuestions);
    // };

    // const onSavedNamesClick = () => {
    //   setShowSavedNames(!showSavedNames);
    // };

    return (
      <div className="form-header">
        <img src={Logo} alt="Living Trust" onClick={onLogoClick} />
        <div className="form-header-right">
          {/* <div onClick={onQuestionsClick} role="button" tabIndex={0}>
          <img src={showQuestions ? chartActive : chartInactice} alt="" />
          <span>Notes & Questions</span>
        </div>
        <div onClick={onSavedNamesClick} role="button" tabIndex={0}>
          <img src={showSavedNames ? saveActive : saveInactive} alt="" />
          <span>Saved Names</span>
        </div> */}
          <div>
            <img src={picture} alt="" className="form-header-user-icon" />
            <span>{name}</span>
          </div>
          <div>
            <Button
              className="form-signout-button"
              variant="secondary"
              onClick={signout}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </div>
    );
  };
