/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import Button from "../Button";
import chevronRight from "../../assets/images/chevrons-right.svg";
import "./NextButton.scss";

export default (props) => (
  <Button {...props} className="next-button">
    Next
    <img src={chevronRight} alt="" />
  </Button>
);
