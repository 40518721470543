import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BackButton from "../../../../components/BackButton";
import UserInfoContinueCard from "../../../../components/UserInfoContinueCard";
import NextButton from "../../../../components/NextButton";
import FormRadio from "../../../../components/FormRadio";
import FormInput from "../../../../components/FormInput";
import FormCard from "../../../../components/FormCard";
import { formActionCreators } from "../../../../actions/form.actions";
import { formatPhoneNumber, getUpdatedSavedNames } from "../../../../helpers";

import "./SpouseDetails.scss";
import "../../Form.scss";

const spouseDetailsSchema = yup.object().shape({
  firstName: yup.string().required("* FirstName is required"),
  lastName: yup.string().required("* LastName is required"),
  phone: yup.string(),
  dob: yup.string(),
  email: yup.string().email("Email is not valid"),
  gender: yup.string().required("* Gender is required"),
  isUsCitizen: yup.string().required("This field is required"),
  isAddressSameAsSpouse: yup.string().required("This field is required"),
  prenuptialAgreement: yup.string().required("This field is required"),
  seperateProperty: yup.string().required("This field is required"),
  address: yup
    .string()
    .when("isAddressSameAsSpouse", (isAddressSameAsSpouse) => {
      if (isAddressSameAsSpouse === "no")
        return yup.string().required("* Address is required");
      return yup.string();
    }),
  city: yup.string().when("isAddressSameAsSpouse", (isAddressSameAsSpouse) => {
    if (isAddressSameAsSpouse === "no")
      return yup.string().required("* City is required");
    return yup.string();
  }),
  county: yup
    .string()
    .when("isAddressSameAsSpouse", (isAddressSameAsSpouse) => {
      if (isAddressSameAsSpouse === "no")
        return yup.string().required("* County is required");
      return yup.string();
    }),
  state: yup.string().when("isAddressSameAsSpouse", (isAddressSameAsSpouse) => {
    if (isAddressSameAsSpouse === "no")
      return yup.string().required("* State is required");
    return yup.string();
  }),
  zipCode: yup
    .string()
    .when("isAddressSameAsSpouse", (isAddressSameAsSpouse) => {
      if (isAddressSameAsSpouse === "no")
        return yup
          .number()
          .typeError("Zip Code must be a number")
          .required("* ZipCode is required");
      return yup.string();
    }),
});

export default ({
  setActiveUserInfomSubItems,
  activeUserInformSubItems,
  goBackSubItem,
}) => {
  const dispatch = useDispatch();
  const userInfoState = useSelector((state) => state.userInfoState);
  // const profile = useSelector((state) => state.profile);

  const { userInfo } = userInfoState;
  const { userInformation } = userInfo || {};
  const { spouseDetails, addressDetails } = userInformation || {};
  const { isChildrenAvailable } = addressDetails || {};

  const {
    gender,
    isUsCitizen,
    isAddressSameAsSpouse,
    prenuptialAgreement,
    seperateProperty,
    ...restSpuseDetails
  } = spouseDetails || {};

  const {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(spouseDetailsSchema),
    defaultValues: {
      ...restSpuseDetails,
      gender: gender || "female",
      isUsCitizen: isUsCitizen || "yes",
      isAddressSameAsSpouse: isAddressSameAsSpouse || "yes",
      prenuptialAgreement: prenuptialAgreement || "no",
      seperateProperty: seperateProperty || "no",
    },
  });

  const watchIsAddressSameAsSpouse = watch("isAddressSameAsSpouse");
  const watchFirstName = watch("firstName");
  const watchLastName = watch("lastName");
  const watchDOB = watch("dob");
  const watchEmail = watch("email");
  const watchPhone = watch("phone");

  const savedNamesState = useSelector((state) => state.savedNamesState);
  const { savedNames } = savedNamesState;

  const saveNamesToSavedNames = () => {
    const newSavedName = {
      firstName: watchFirstName,
      lastName: watchLastName,
      dob: watchDOB,
      phone: watchPhone,
      email: watchEmail,
    };
    const updatedSavedNames = getUpdatedSavedNames(savedNames, newSavedName);
    dispatch(formActionCreators.postSavedNames(updatedSavedNames));
  };

  const submitSpouseDetails = (data) => {
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          spouseDetails: data,
        },
      })
    );
    saveNamesToSavedNames();
    if (isChildrenAvailable === "yes") {
      setActiveUserInfomSubItems([
        ...activeUserInformSubItems,
        "childrenDetails",
      ]);
    } else {
      // goToNextNavItem();
      setActiveUserInfomSubItems([...activeUserInformSubItems, "videoDetails"]);
    }
  };

  const triggerAutoSave = () => {
    const data = getValues();
    dispatch(
      formActionCreators.postFormData({
        userInformation: {
          spouseDetails: data,
        },
      })
    );
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setValue("phone", formattedPhone);
  };

  return (
    <div className="spouse-details-wrapper">
      <UserInfoContinueCard name="Please tell us about your spouse." />
      <form onSubmit={handleSubmit(submitSpouseDetails)}>
        <FormCard>
          <span className="form-title">Spouse&apos;s Information</span>
          <div className="form-controls">
            <div className="form-controls-section">
              <FormInput
                label="First Name"
                name="firstName"
                error={errors.firstName?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
              <FormInput
                label="Last Name"
                name="lastName"
                error={errors.lastName?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
            </div>
            <div className="form-controls-section">
              <FormInput
                label="Phone"
                name="phone"
                error={errors.phone?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
                onChange={handlePhoneChange}
              />
              <FormInput
                label="DOB"
                name="dob"
                type="date"
                error={errors.dob?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
            </div>
            <FormInput
              label="Email"
              name="email"
              error={errors.email?.message}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
            <FormRadio
              name="gender"
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ]}
              inputRef={register}
              label="Gender"
              onBlur={triggerAutoSave}
            />
            <FormRadio
              label="US Citizen?"
              name="isUsCitizen"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
            <FormRadio
              label="Same address as spouse/patner?"
              name="isAddressSameAsSpouse"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
            />
            <FormRadio
              label="Prenuptial Agreement"
              name="prenuptialAgreement"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
            <FormRadio
              label="Separate Property"
              name="seperateProperty"
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              inputRef={register}
              onBlur={triggerAutoSave}
            />
          </div>
        </FormCard>
        {watchIsAddressSameAsSpouse === "no" && (
          <FormCard>
            <span className="form-title">Spouse address</span>
            <div className="form-controls">
              <FormInput
                label="Address"
                name="address"
                error={errors.address?.message}
                inputRef={register}
                onBlur={triggerAutoSave}
              />
              <div className="form-controls-section">
                <FormInput
                  label="City"
                  name="city"
                  error={errors.city?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="County"
                  name="county"
                  error={errors.county?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
              </div>
              <div className="form-controls-section">
                <FormInput
                  label="State"
                  name="state"
                  error={errors.state?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
                <FormInput
                  label="Zip Code"
                  name="zipCode"
                  error={errors.zipCode?.message}
                  inputRef={register}
                  onBlur={triggerAutoSave}
                />
              </div>
            </div>
          </FormCard>
        )}

        <div className="form-details-buttons">
          <BackButton type="button" onClick={goBackSubItem} />
          <NextButton type="submit" />
        </div>
      </form>
    </div>
  );
};
